export const radioButtons = [
  {
    id: 'monochrome',
    value: 'monochrome',
    label: 'Монохром',
    name: 'gradientType',
    gradientType: 'monochrome',
  },
  {
    id: 'dpdl',
    value: 'dpdl',
    label: 'ΔP/ΔL',
    name: 'gradientType',
    gradientType: 'dpdl',
  },
  {
    id: 'wor',
    value: 'wor',
    label: 'WOR',
    name: 'gradientType',
    gradientType: 'wor',
  },
];
