import React from 'react';
import { ValueType } from 'react-select';

import classnames from 'classnames';

import SettingsRadioButton from '../../CalcSettings/SettingsRadioButton';
import Dropdown from '../../components/Dropdown';
import { dateTimeValueError, OptionType } from '../types';

import '../TaskSettings/taskSettings.scss';
import './detailedTaskSettings.scss';

interface IDetailedTaskSettingsProps {
  setAdaptationMethod(value: string): void;
  dateTimeOptions: OptionType[];
  setDateTimeValue(val: ValueType<OptionType, false>): void;
  dateTimeValue: ValueType<OptionType, false>;
  dateTimeValueError: dateTimeValueError;
  adaptationMethod: string;
}

const DetailedTaskSettings: React.FC<IDetailedTaskSettingsProps> = ({
  setAdaptationMethod,
  dateTimeOptions,
  dateTimeValue,
  setDateTimeValue,
  dateTimeValueError,
  adaptationMethod,
}) => {
  return (
    <div className="settings-content-container task-settings-container">
      <span className="settings-subtitle">
        4. Настройки расчета для адаптации сети
      </span>
      <div className="settings-panel">
        <span className="calc-settings-method-label settings-dropdown-label">
          Дата и время
        </span>
        <div className="detailed-task-settings-dropdown-container">
          <Dropdown
            options={dateTimeOptions}
            placeholder=""
            value={dateTimeValue}
            onChange={setDateTimeValue}
            error={dateTimeValueError.error}
          />
          <div
            className={classnames(
              'settings-tooltip detailed-task-settings-dropdown-tooltip',
              dateTimeValueError.error &&
                dateTimeValueError.errorType === 'notChosen'
                ? 'error'
                : '',
            )}
          >
            <span className="settings-tooltip-text">
              Выберите дату и время для расчета
            </span>
          </div>
        </div>
        <span className="calc-settings-method-label settings-dropdown-label">
          Метод адаптации
        </span>
        <div className="calc-settings-radio-row">
          <SettingsRadioButton
            id="task-settings-first-radio"
            value="NUMDES"
            setValue={setAdaptationMethod}
            label="Классические методы оптимизации"
            name="taskSettings"
            checked={adaptationMethod === "NUMDES"}
          />
          <SettingsRadioButton
            id="task-settings-second-radio"
            value="MAT"
            setValue={setAdaptationMethod}
            label="Мультиагентные методы оптимизации"
            name="taskSettings"
            checked={adaptationMethod === "MAT"}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailedTaskSettings;
