import * as React from 'react';
import { connect } from 'react-redux';

import { ReactComponent as ShowLabelIcon } from 'assets/images/labels-icon.svg';
import classnames from 'classnames';
import { selectShowLables, setShowLables } from 'Map/mapSlice';
import { StoreType } from 'store';

import { IShowLabelsProps } from './types';

const ShowLabels: React.FC<IShowLabelsProps> = props => {
  const onClickHandler = () => {
    const { showLables } = props;
    props.setShowLables(!showLables);
  };

  return (
    <div className="show-gradient-container">
      <button
        onClick={onClickHandler}
        className={classnames('set-center-button', {
          active: props.showLables,
        })}
      >
        <ShowLabelIcon />
      </button>
    </div>
  );
};

const mapStateToProps = (store: StoreType) => ({
  showLables: selectShowLables(store),
});

const mapDispatchToProps = {
  setShowLables,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowLabels);
