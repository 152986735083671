import React from 'react';

interface ISettingsRadioButtonProps {
  value: string;
  setValue?(value: string): void;
  label: string;
  disabled?: boolean;
  name: string;
  id: string;
  checked?: boolean;
}

const SettingsRadioButton: React.FC<ISettingsRadioButtonProps> = ({
  value,
  setValue,
  disabled,
  name,
  label,
  id,
  ...inputProps
}) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof setValue === 'function') setValue(e.target.value);
  };

  return (
    <div className="task-settings-row checkbox-row ">
      <input
        type="radio"
        aria-checked={false}
        className="task-settings-radio-input"
        value={value}
        onChange={onChangeValue}
        disabled={disabled}
        name={name}
        id={id}
        {...inputProps}
      />
      <label htmlFor={id} className="task-settings-checkbox-label">
        {label}
      </label>
    </div>
  );
};

export default SettingsRadioButton;
