import React, { createRef } from 'react';
import { ValueType } from 'react-select';

import classnames from 'classnames';

import Dropdown from '../../components/Dropdown';
import { overflowOnResize } from '../overflowOnResize';
import ScrollableWrapper from '../ScrollableWrapper';
import { OptionType, UploadedFileType, WellfieldErrorType } from '../types';
import Droparea from './Droparea';
import ErrorsList from './ErrorsList';
import { IWellfieldSettingsProps, IWellfieldSettingsState } from './types';
import UploadedFile from './uploadedFile';

class WellfieldSettings extends React.Component<IWellfieldSettingsProps> {
  panelRef = createRef<HTMLDivElement>();

  state: IWellfieldSettingsState = {
    panelStyle: { overflow: 'unset' },
    scrolled: false,
    added: false,
  };

  componentDidMount() {
    const ref = this.panelRef.current;
    window.addEventListener('resize', this.onResize);
    ref?.addEventListener('scroll', this.onScroll);
    this.onResize();
  }

  componentDidUpdate(prevProps: IWellfieldSettingsProps) {
    if (prevProps.uploadedFiles.length !== this.props.uploadedFiles.length) {
      this.onResize();
      this.onAdd();
    }
    if (prevProps.dropzoneError.error !== this.props.dropzoneError.error) {
      this.onResize();
    }
    if (
      prevProps.uploadedFilesErrors.length !==
      this.props.uploadedFilesErrors.length
    ) {
      this.onResize();
    }
  }

  componentWillUnmount() {
    const ref = this.panelRef.current;
    window.removeEventListener('resize', this.onResize);
    ref?.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    const { scrolled } = this.state;
    this.setState({ scrolled: !scrolled });
  };

  onAdd = () => {
    const { added } = this.state;
    this.setState({ added: !added });
  };

  onResize = () => {
    overflowOnResize(this.panelRef, this.setPanelStyle);
  };

  setPanelStyle = style => {
    this.setState({ panelStyle: style });
  };

  onFileUpload = (files: UploadedFileType[]) => {
    const savedFiles = [...this.props.uploadedFiles, ...files];
    this.props.setUploadedFiles(savedFiles);
    if (
      this.props.dropzoneError &&
      typeof this.props.setDropzoneError === 'function'
    ) {
      this.props.setDropzoneError({ error: false, errorsList: [] });
    }
  };

  onFileDelete = (index: number) => {
    const files = [...this.props.uploadedFiles];
    files.splice(index, 1);
    const isErrors = files.find(value => value.error.error);
    if (!isErrors) {
      this.props.setDropzoneError({ error: false, errorsList: [] });
    }
    this.props.setUploadedFiles(files);
  };

  onChangeWellfield = (e: ValueType<OptionType, false>) => {
    const { showWellfieldDialog } = this.props;
    if ((e as OptionType).value === 'new') {
      showWellfieldDialog();
    } else {
      this.props.setWellfieldValue(e);
      if (
        this.props.wellfieldValueError?.error &&
        typeof this.props.setWellfieldValueError === 'function'
      ) {
        this.props.setWellfieldValueError({ error: false });
      }
    }
  };

  getMessageByErrorType = (
    errorType: keyof typeof WellfieldErrorType | undefined,
  ) => {
    switch (errorType) {
      case 'notChosen':
        return 'Для расчета необходимо выбрать месторождение';
      case 'notFound':
        return 'На данном месторождении отсутствуют центры сбора';
      default:
        return '';
    }
  };

  render() {
    const { panelStyle, scrolled, added } = this.state;
    const {
      wellfieldOptions,
      wellfieldValue,
      wellfieldValueError,
      dropzoneError,
      uploadedFiles,
    } = this.props;
    const options: OptionType[] = [];
    wellfieldOptions.forEach(value => {
      options.push(value.option);
    });
    return (
      <ScrollableWrapper
        divRef={this.panelRef}
        bottomShadowClass="settings-bottom-shadow"
      >
        <div
          className="settings-content-container wellfield-settings-container"
          ref={this.panelRef}
          style={panelStyle}
        >
          <span className="settings-subtitle">1. Выбор месторождения</span>
          <div className="settings-panel">
            <span className="settings-dropdown-label">Месторождение</span>
            <div className="settings-dropdown-container wellfield-settings-dropdown-container relative">
              <Dropdown
                options={options}
                placeholder=""
                value={wellfieldValue?.option}
                onChange={this.onChangeWellfield}
                error={wellfieldValueError?.error}
                withButton
              />
              <div
                className={classnames(
                  'settings-tooltip',
                  wellfieldValueError?.error &&
                    wellfieldValueError?.errorType &&
                    'error',
                )}
              >
                <span className="settings-tooltip-text">
                  {this.getMessageByErrorType(wellfieldValueError?.errorType)}
                </span>
              </div>
            </div>
            {wellfieldValue?.link ? (
              <a
                className="wellfield-settings-link filled-link"
                href={wellfieldValue?.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Проект в ЭРА:ИСКРА / редакторе
              </a>
            ) : (
              <span className="wellfield-settings-link empty-link">
                Проект в ЭРА:ИСКРА / редакторе
              </span>
            )}
            <span className="settings-dropdown-label files-label">
              Файлы для расчета
            </span>
            <span className="settings-dropdown-label files-sub-label">
              Для осуществления расчетов необходимы следующие файлы:{' '}
              <span className="bold">«ТехРежим.xls»</span>,{' '}
              <span className="bold">«Скважины.xlsx»</span> и{' '}
              <span className="bold">
                «Фактические измерения из OIS Pipe.xlsx»
              </span>
            </span>
            <div className="mode-table-wrapper relative">
              <Droparea
                onFileUpload={this.onFileUpload}
                uploadedFiles={uploadedFiles}
                error={dropzoneError}
              />
              {uploadedFiles?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <UploadedFile
                      fileName={item.name}
                      error={item.error}
                      index={index}
                      onFileDelete={this.onFileDelete}
                      panRef={this.panelRef}
                      scrolled={scrolled}
                      added={added}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <ErrorsList
            uploadedFiles={uploadedFiles}
            dropzoneError={dropzoneError}
          />
        </div>
      </ScrollableWrapper>
    );
  }
}

export default WellfieldSettings;
