import * as React from 'react';

import UserLogo from '../components/UserLogo';
import Map from '../Map';

const Wrapper: React.FC = props => {
  return (
    <>
      <UserLogo />
      <Map />
      {props.children}
    </>
  );
};

export default Wrapper;
