import React from 'react';

import classnames from 'classnames';

import SkeletonComponent from './SkeletonComponent';
import { ITableRowProps } from './types';

const TableRow: React.FC<ITableRowProps> = ({
  el,
  active,
  headKeys,
  activeHover,
  onClickHandler,
  onMouseEnterHandler,
  onMouseLeaveHandler,
}) => {
  const onClick = () => onClickHandler(el);
  const onMouseEnter = () => onMouseEnterHandler(el);

  return (
    <tr
      onMouseLeave={onMouseLeaveHandler}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      className={classnames('table-row', {
        active,
        'active-hover': activeHover,
      })}
    >
      {headKeys.map((key: string, tdIndex: number) => (
        <td className="table-data" key={tdIndex}>
          {SkeletonComponent(el[key], tdIndex)}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
