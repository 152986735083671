import * as React from 'react';

import { formatter } from 'utils';
import { TableRowPropsType } from './types';

const TableRow: React.FC<TableRowPropsType> = ({ title, data }) => {
  const { pressure, frequency, fluidFlow, oilFlow, gasFlow } = data;

  return (
    <tr className="popup-table-row">
      <td className="popup-title popup-table-col">{title}</td>
      <td className="popup-table-dem">
        <span className="popup-val popup-val-dem">F, Гц</span>
        <span className="popup-val popup-val-dem">P, атм</span>
      </td>
      <td className="popup-title popup-table-data popup-table-col">
        <span className="popup-val">{formatter.format(frequency)}</span>
        <span className="popup-val">{formatter.format(pressure)}</span>
      </td>
      <td className="popup-table-dem">
        <span className="popup-val popup-val-dem">
          Qж,{' '}
          <span className="relative">
            м<span className="index">3</span>
          </span>{' '}
          /сут
        </span>
        <span className="popup-val popup-val-dem">Qн, т/сут</span>
        <span className="popup-val popup-val-dem">
          Qг, тыс.{' '}
          <span className="relative">
            м<span className="index">3</span>
          </span>{' '}
          /сут
        </span>
      </td>
      <td className="popup-title popup-table-data">
        <span className="popup-val">{formatter.format(fluidFlow)}</span>
        <span className="popup-val">{formatter.format(oilFlow)}</span>
        <span className="popup-val">{formatter.format(gasFlow)}</span>
      </td>
    </tr>
  );
};

export default TableRow;
