import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import classnames from 'classnames';
import { StoreType } from 'store';

import { selectTaskSolution } from '../networkSlice';
import AdaptedTable from './AdaptedTable';
import RestoredTable from './RestoredTable';
import { TablesProps, TablesStateType } from './types';

class Tables extends React.Component<TablesProps> {
  ref = React.createRef<HTMLDivElement>();

  state: TablesStateType = {
    top: 0,
    adapted: false,
  };

  componentDidMount() {
    this.handleRoute();
  }

  componentDidUpdate(prevProps: TablesProps, prevState: TablesStateType) {
    if (prevState.top > this.state.top) {
      const parentNode = this.ref.current?.parentNode?.parentNode as any;
      if (parentNode) {
        parentNode.scrollTop = this.state.top;
      }
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleRoute();
    }
  }

  handleRoute = () => {
    const {
      location: { pathname },
    } = this.props;
    const adapted = pathname.includes('adapted');
    this.setState({ adapted });
  };

  onSelect = () => {
    const parentNode = this.ref.current?.parentNode?.parentNode as any;
    const scrollTop = parentNode.scrollTop;
    this.setState({ top: scrollTop });
  };

  render() {
    const { adapted } = this.state;

    return (
      <div ref={this.ref} className={classnames('network-tables')}>
        {adapted ? (
          <AdaptedTable onSelect={this.onSelect} />
        ) : (
          <RestoredTable onSelect={this.onSelect} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: StoreType) => ({
  taskSolution: selectTaskSolution(state),
});

export default connect(mapStateToProps)(withRouter(Tables));
