/**
 * @param  {number} fadeFraction
 * @param  {[number, number, number]} rgbColor1 - array of rgb
 * @param  {[number, number, number]} rgbColor2 - array of rgb
 * @param  {[number, number, number]} rgbColor3 - array of rgb
 * @returns {string} 'rgb(number, number, number)'
 */

export default function colorGradient(
  fadeFraction: number,
  rgbColor1: [number, number, number],
  rgbColor2: [number, number, number],
  rgbColor3: [number, number, number],
) {
  let color1 = rgbColor1;
  let color2 = rgbColor2;
  let fade = fadeFraction;

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade *= 2;

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = rgbColor2;
      color2 = rgbColor3;
    }
  }

  const diffRed = color2[0] - color1[0];
  const diffGreen = color2[1] - color1[1];
  const diffBlue = color2[2] - color1[2];

  const gradient = {
    red: parseInt(Math.floor(color1[0] + diffRed * fade).toString(), 10),
    green: parseInt(Math.floor(color1[1] + diffGreen * fade).toString(), 10),
    blue: parseInt(Math.floor(color1[2] + diffBlue * fade).toString(), 10),
  };

  return `rgb(${gradient.red},${gradient.green},${gradient.blue})`;
}
