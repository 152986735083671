import * as React from 'react';
import { connect } from 'react-redux';
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import ErrorPage from './ErrorPage';
import Login from './Login';
import { getAuth, setAuth } from './Login/authSlice';
import Network from './Network';
import { handleStorage } from './services/handleStorage';
import Settings from './Settings';
import { StoreType } from './store';

export type AppPropsType = {
  setAuth: typeof setAuth;
  auth: any;
};

type AppStateType = {
  auth: boolean;
};

const initToken = handleStorage.getToken();

const errorPage = () => <ErrorPage type="notFoundError" />;

class App extends React.Component<AppPropsType> {
  state: AppStateType = {
    auth: !!initToken,
  };

  componentDidMount(): void {
    this.props.setAuth(this.state.auth);
  }

  componentDidUpdate(prevProps: AppPropsType): void {
    if (prevProps.auth !== this.props.auth) {
      this.setState({ auth: this.props.auth });
    }
  }

  render() {
    const { auth } = this.state;
    return (
      <Router>
        <Switch>
          <PrivateRoute
            auth={auth}
            component={Settings}
            exact
            path="/settings"
          />
          <PrivateRoute
            auth={auth}
            component={Network}
            exact
            path="/network/:id"
          />
          <PrivateRoute
            auth={auth}
            component={Network}
            exact
            path="/optimized-network/:id"
          />
          <PrivateRoute
            auth={auth}
            component={Network}
            exact
            path="/adapted-network/:id"
          />
          <PrivateRoute
            auth={auth}
            component={Network}
            exact
            path="/adapted-network/:id/restore"
          />
          <Route component={Login} exact path="/login" />
          <Route exact path="/">
            <Redirect to="/settings" />
          </Route>
          <Route path="*" render={errorPage} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  auth: getAuth(store),
});

const mapDispatchToProps = {
  setAuth,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
