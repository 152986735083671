import {
  SetGradientType,
  SetGradientValues,
  SetPopupDataType,
  SetShowLables,
  SetWellfieldDataType,
} from './types';

const setPopupData: SetPopupDataType = (state, action) => {
  state.popup = action.payload;
};

const setWellfieldData: SetWellfieldDataType = (state, action) => {
  const nodes = action.payload?.nodes;
  const wells = action.payload?.wells;
  const wellfieldUid = action.payload?.wellfield_uid;
  const nodesById = nodes?.reduce((acc = {}, cur) => {
    const uid = cur.uid;
    if (!uid) return acc;
    if (!acc[uid]) {
      acc[uid] = [];
    }
    acc[uid].push(cur);

    return acc;
  }, {});
  if (!nodes || !wells || !nodesById || !wellfieldUid) {
    state.wellfieldData = null;
  } else {
    const pipelines = action.payload?.pipelines.reduce((acc: any = [], cur) => {
      acc.push({
        ...cur,
        start_node_name: nodesById![cur.start_node_uid][0].name,
        end_node_name: nodesById![cur.end_node_uid][0].name,
      });
      return acc;
    }, []);

    state.wellfieldData = {
      nodes,
      wells,
      pipelines,
      wellfield_uid: wellfieldUid,
      geometry_center: action.payload?.geometry_center,
    };
  }
};

const setGradientType: SetGradientType = (state, action) => {
  state.gradientType = action.payload;
};

const setGradientValues: SetGradientValues = (state, action) => {
  state.gradientValues = action.payload;
};

const setShowLables: SetShowLables = (state, action) => {
  state.showLables = action.payload;
};

export default {
  setPopupData,
  setShowLables,
  setGradientType,
  setWellfieldData,
  setGradientValues,
};
