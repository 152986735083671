import * as React from 'react';

import { IPopupTopRowProps } from './types';

const PopupTopRow: React.FC<IPopupTopRowProps> = ({
  firstTitle,
  secondTitle,
}) => (
  <div className="popup-top-row">
    <div className="popup-row__sub-row">
      <p className="popup-title">Трубопровод</p>
    </div>
    <div className="popup-row__sub-row">
      <p className="popup-sub-title">
        {firstTitle} — {secondTitle}
      </p>
    </div>
  </div>
);

export default PopupTopRow;
