import * as React from 'react';

import dayjs from 'dayjs';
import { PipelineNetworkNode, Well } from 'generatedApiTypes';

import { ReactComponent as AngleIcon } from '../assets/images/angle.svg';
import { formatter } from '../utils';

export const dashboardItemsAdapted = ({
  date,
  standard_deviation,
  gc_pressure,
}) => [
  {
    dem: 'Дата и время расчета',
    value: dayjs(date).format('DD.MM.YY hh:mm:ss'),
    diff: false,
  },
  {
    dem: 'СКО давлений, атм',
    value: standard_deviation,
    diff: false,
  },
  {
    dem: 'P на входе в ЦС, атм',
    value: gc_pressure,
    diff: false,
  },
];

export const dashboardItems = (
  {
    gc_water_oil_ratio,
    max_dp_dl_atm_km,
    mean_dp_dl_atm_km,
    total_fluid_flow_rate_m3_day,
    total_gas_flow_rate_m3_day,
    total_oil_flow_rate_ton_day,
    d_total_fluid_flow_rate_m3_day,
    d_total_oil_flow_rate_ton_day,
    d_total_gas_flow_rate_m3_day,
    d_gc_water_oil_ratio,
    d_mean_dp_dl_atm_km,
    d_max_dp_dl_atm_km,
  }: any = {},
  optimized?,
) => [
  {
    dem: (
      <>
        Σ Qж,{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>{' '}
        /сут
      </>
    ),
    value: total_fluid_flow_rate_m3_day,
    diff: optimized ? d_total_fluid_flow_rate_m3_day : false,
  },
  {
    dem: 'Σ Qн, т/сут',
    value: total_oil_flow_rate_ton_day,
    diff: optimized ? d_total_oil_flow_rate_ton_day : false,
  },
  {
    dem: (
      <>
        Σ Qг, тыс.{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>{' '}
        /сут
      </>
    ),
    value: total_gas_flow_rate_m3_day,
    diff: optimized ? d_total_gas_flow_rate_m3_day : false,
  },
  {
    dem: 'Обводненность на ЦС, %',
    value: gc_water_oil_ratio,
    diff: optimized ? d_gc_water_oil_ratio : false,
  },
  {
    dem: 'Ср. ΔP/ΔL, атм/км',
    value: mean_dp_dl_atm_km,
    diff: optimized ? d_mean_dp_dl_atm_km : false,
  },
  {
    dem: 'Макс. ΔP/ΔL, атм/км',
    value: max_dp_dl_atm_km,
    diff: optimized ? d_max_dp_dl_atm_km : false,
  },
];

export const comparationItems = (
  parameters: {
    d_f_avg;
    d_f_max;
    d_p_avg;
    d_p_max;
    d_gas_flow_max;
    d_gas_flow_avg;
    d_fluid_flow_avg;
    d_fluid_flow_max;
    d_oil_flow_avg;
    d_oil_flow_max;
  } | null,
) => [
  {
    dem: 'δF, %',
    value: parameters
      ? `${formatter.format(parameters?.d_f_avg)} / ${formatter.format(
          parameters?.d_f_max,
        )} `
      : '',
  },
  {
    dem: 'δP, %',
    value: parameters
      ? `${formatter.format(parameters?.d_p_avg)} / ${formatter.format(
          parameters?.d_p_max,
        )}`
      : '',
  },
  {
    dem: 'δQж, %',
    value: parameters
      ? `${formatter.format(parameters?.d_fluid_flow_avg)} / ${formatter.format(
          parameters?.d_fluid_flow_max,
        )}`
      : '',
  },
  {
    dem: 'δQн, %',
    value: parameters
      ? `${formatter.format(parameters?.d_oil_flow_avg)} / ${formatter.format(
          parameters?.d_oil_flow_max,
        )}`
      : '',
  },
  {
    dem: 'δQг, %',
    value: parameters
      ? `${formatter.format(parameters?.d_gas_flow_avg)} / ${formatter.format(
          parameters?.d_gas_flow_max,
        )}`
      : '',
  },
];

export const wellfieldsTableHead = {
  name: 'Скважина',
  well_pad: 'Куст',
  frequency_hz: 'F, Гц',
  pressure_atm: 'P, атм',
  fluid_flow_rate_ton_day: {
    title: 'Qж, м3/сут',
    component: (
      <>
        Σ Qж,{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>{' '}
        /сут
      </>
    ),
  },
  oil_flow_rate_ton_day: 'Qн, т/сут',
  gas_flow_rate_m3_day: {
    title: 'Qг, тыс. м3/сут',
    component: (
      <>
        Σ Qг, тыс.{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>{' '}
        /сут
      </>
    ),
  },
};

export const aspoTableHead = {
  start_node: 'V0',
  end_node: 'V1',
  length_m: 'L, м',
  diameter_mm: 'D, мм',
  wall_thickness_mm: 'S, мм',
  aspo_mm: 'АСПО, мм',
  fluid_speed: 'υ, м/с',
  dp_dl_atm_km: 'ΔP/ΔL, атм/км',
};

export const adaptedTableHead = {
  start_node: 'V0',
  end_node: 'V1',
  length_m: 'L, м',
  diameter_mm: 'D, мм',
  wall_thickness_mm: 'S, мм',
  aspo_mm: 'АСПО, мм',
  pressure_calc: 'Pр, атм',
  pressure_fact: 'Pф, атм',
  dp_abs: 'ΔP, атм',
  dp_rel: 'δP, %',
};

export const nodesTableHead = {
  name: 'Узел',
  pressure_atm: 'P, атм',
  oil_flow_rate_ton_day: {
    title: 'Qж, м3/сут',
    component: (
      <>
        Σ Qж,{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>{' '}
        /сут
      </>
    ),
  },
  fluid_flow_rate_ton_day: 'Qн, т/сут',
  gas_flow_rate_m3_day: {
    title: 'Qг, тыс. м3/сут',
    component: (
      <>
        Σ Qг, тыс.{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>{' '}
        /сут
      </>
    ),
  },
};

export const pipesTableHead = {
  start_node_name: {
    title: 'V0',
    component: (
      <>
        <span className="relative">
          V<span className="sub-index">0</span>
        </span>
      </>
    ),
  },
  end_node_name: {
    title: 'V1',
    component: (
      <>
        <span className="relative">
          V<span className="sub-index">1</span>
        </span>
      </>
    ),
  },
  aspo_mm: 'АСПО, мм',
  theta_grad: {
    title: ', рад',
    component: (
      <>
        <AngleIcon style={{ verticalAlign: 'bottom' }} /> , рад
      </>
    ),
  },
  dp_dl_atm_km: 'ΔP/ΔL, атм/км',
  wor: 'WOR,%',
  oil_flow_rate_ton_day: 'Qн, т/сут',
  fluid_speed: 'υ, м/с',
};

export const composeById = (acc = {}, cur: Well) => {
  if (!acc[cur.node_uid]) {
    acc[cur.node_uid] = [];
  }
  acc[cur.node_uid].push(cur);
  return acc;
};

export const composeByNodeId = (acc = {}, cur: PipelineNetworkNode) => {
  const uid = cur.uid;

  if (!uid) return acc;

  if (!acc[uid]) {
    acc[uid] = [];
  }
  acc[uid].push(cur);

  return acc;
};
