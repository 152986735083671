import * as React from 'react';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import {
  CalibrationSolution,
  PipelineNetworkSegmentCalibrationResult,
} from 'generatedApiTypes';
import { selectTaskSolution } from 'Network/networkSlice';
import { StoreType } from 'store';

import { adaptedTableHead, aspoTableHead } from '../utils';
import Table from './Table';
import { TablesPropsType } from './types';
import { calculateMidPoint, composeNodesById, filterById } from './utils';

const AdaptedTables: React.FC<TablesPropsType> = props => {
  const taskSolution = props.taskSolution as CalibrationSolution;
  const wellfieldGraph = taskSolution?.wellfield_graph;
  const nodesById = wellfieldGraph?.nodes.reduce(composeNodesById, {});

  const segments = taskSolution?.segments?.map(({ segment, ...rest }) => {
    const midCoords = calculateMidPoint(segment.coords![0], segment.coords![1]);

    return {
      ...segment,
      ...rest,
      midCoords,
      start_node: nodesById[segment?.start_node_uid]?.name,
      end_node: nodesById[segment?.end_node_uid]?.name,
      start_node_name: nodesById[segment?.start_node_uid]?.name,
      end_node_name: nodesById[segment?.end_node_uid]?.name,
    };
  });

  const pipelines = wellfieldGraph?.pipelines.map(el => {
    const midCoords = calculateMidPoint(el.coords![0], el.coords![1]);
    const segment = segments.filter(filterById(el))[0];
    const segmentProps: Partial<PipelineNetworkSegmentCalibrationResult> = {};
    const fieldNames: Array<keyof PipelineNetworkSegmentCalibrationResult> = [
      'dp_abs',
      'dp_rel',
      'pressure_calc',
      'pressure_fact',
    ];

    if (segment) {
      fieldNames.forEach(field => {
        segmentProps[field] = segment[field];
      });
    }

    return {
      ...el,
      midCoords,
      start_node: nodesById[el?.start_node_uid]?.name,
      end_node: nodesById[el?.end_node_uid]?.name,
      start_node_name: nodesById[el?.start_node_uid]?.name,
      end_node_name: nodesById[el?.end_node_uid]?.name,
      ...segmentProps,
    };
  });

  return (
    <Tabs onSelect={props.onSelect}>
      <TabList>
        <Tab>Полученные АСПО</Tab>
        <Tab>Результаты адаптации</Tab>
      </TabList>
      <div className="network-tables__container">
        <TabPanel>
          {!!pipelines && (
            <Table
              type="adaptation"
              tableHead={aspoTableHead}
              data={pipelines}
            />
          )}
        </TabPanel>
        <TabPanel>
          {segments && (
            <Table
              type="adaptation"
              tableHead={adaptedTableHead}
              data={segments}
            />
          )}
        </TabPanel>
      </div>
    </Tabs>
  );
};

const mapStateToProps = (state: StoreType) => ({
  taskSolution: selectTaskSolution(state),
});

export default connect(mapStateToProps)(AdaptedTables);
