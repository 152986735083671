import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getTraces } from 'Settings/service';
import { StoreType } from 'store';

import Button from 'components/Button';
import LeftColSubTopRow from 'components/LeftCol/LeftColSubTopRow';
import { selectTask } from './networkSlice';
import { calculateOptimizedTask, downloadResult } from './service';
import {
  INetworkTopRowProps,
  INetworkTopRowState,
  TaskStatuses,
} from './types';

class NetworkTopRow extends React.Component<INetworkTopRowProps> {
  state: INetworkTopRowState = {
    buttonTitle: 'Восстановить сеть...',
    restoreButtonMode: 'primary',
    restoreNetwork: false,
    mainTitle: 'Адаптированная сеть',
  };

  componentDidMount() {
    this.handleRoute();
  }

  componentDidUpdate(prevProps: INetworkTopRowProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleRoute();
    }
  }

  optimizeSolution = async () => {
    if (this.state.restoreButtonMode === 'execution') return;
    const {
      location: { pathname },
      match: { params },
      task,
    } = this.props;
    const { id } = params as { id?: string };
    const optimized = pathname.includes('optimized');
    const adapted = pathname.includes('adapted');

    if (adapted) {
      this.setState(
        {
          restoreNetwork: true,
        },
        () => {
          this.props.history.push(`/adapted-network/${id}/restore`);
        },
      );
    } else if (!optimized) {
      if (task && id) {
        try {
          this.setState({
            restoreButtonMode: 'execution',
            buttonTitle: 'Оптимизация...',
          });
          const { uid: taskUid, ...restParams } = task;
          const { data } = await calculateOptimizedTask({
            id,
            task: {
              ident_task_id: taskUid,
              ...restParams,
            },
          });
          const uid = data?.uid;

          if (uid) {
            await this.getTaskTrace(uid);
          }
        } catch (err) {
          this.setState({ restoreButtonMode: 'primary' });
        }
      }
    } else if (id) downloadResult(id);
  };

  getTaskTrace = async (id: string) => {
    try {
      const { data } = await getTraces(id);
      const { status } = data;
      const statusOfCompletedTask: TaskStatuses = [
        'SOLVED',
        'FAILED',
        'VALIDATION_ERROR',
      ];
      if (statusOfCompletedTask.includes(status)) {
        this.props.history.replace(`/optimized-network/${id}`);
        this.setState({ restoreButtonMode: 'primary' });
      } else {
        setTimeout(() => {
          this.getTaskTrace(id);
        }, 30000);
      }
    } catch (err) {
      const status: Response['status'] = err.response?.status;
      if (status === 500) {
        this.setState({ internalServerError: true });
      }
    }
  };

  handleRoute() {
    const {
      location: { pathname },
    } = this.props;
    let buttonTitle: INetworkTopRowState['buttonTitle'] = 'Оптимизировать';
    let mainTitle: INetworkTopRowState['mainTitle'] = 'Восстановленная сеть';
    const restoreNetwork = pathname.includes('restore');
    const optimized = pathname.includes('optimized');
    const adapted = pathname.includes('adapted');

    if (restoreNetwork) {
      mainTitle = 'Восстановление сети';
    }
    if (optimized) {
      mainTitle = 'Оптимизированная сеть';
      buttonTitle = 'Скачать результаты';
    }
    if (adapted && !restoreNetwork) {
      mainTitle = 'Адаптированная сеть';
      buttonTitle = 'Восстановить сеть...';
    }
    this.setState({
      restoreNetwork,
      mainTitle,
      buttonTitle,
    });
  }

  render() {
    const {
      mainTitle,
      buttonTitle,
      restoreButtonMode,
      restoreNetwork,
    } = this.state;

    return (
      <LeftColSubTopRow>
        <div className="network-top-row">
          <h1>{mainTitle}</h1>
          {!restoreNetwork && (
            <Button mode={restoreButtonMode} onClick={this.optimizeSolution}>
              {buttonTitle}
            </Button>
          )}
        </div>
      </LeftColSubTopRow>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  task: selectTask(store),
});

export default connect(mapStateToProps)(withRouter(NetworkTopRow));
