import * as React from 'react';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { OptimizationSolution } from 'generatedApiTypes';
import { selectTaskSolution } from 'Network/networkSlice';
import { StoreType } from 'store';

import { nodesTableHead, pipesTableHead, wellfieldsTableHead } from '../utils';
import Table from './Table';
import { TablesPropsType } from './types';
import { mergeNodesStateWithProps } from './utils';

const RestoredTables: React.FC<TablesPropsType> = props => {
  const taskSolution: OptimizationSolution = props.taskSolution;
  const nodesData = taskSolution?.nodes?.map(mergeNodesStateWithProps);

  return (
    <Tabs onSelect={props.onSelect}>
      <TabList>
        <Tab>Скважины</Tab>
        <Tab>Узлы</Tab>
        <Tab>Трубы</Tab>
      </TabList>
      <div className="network-tables__container">
        <TabPanel>
          <Table
            type="wellfield"
            tableHead={wellfieldsTableHead}
            data={taskSolution?.wells}
          />
        </TabPanel>
        <TabPanel>
          <Table type="node" tableHead={nodesTableHead} data={nodesData} />
        </TabPanel>
        <TabPanel>
          <Table
            type="pipe"
            tableHead={pipesTableHead}
            data={taskSolution?.pipelines}
          />
        </TabPanel>
      </div>
    </Tabs>
  );
};

const mapStateToProps = (state: StoreType) => ({
  taskSolution: selectTaskSolution(state),
});

export default connect(mapStateToProps)(RestoredTables);
