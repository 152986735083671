// tslint:disable-next-line: no-single-line-block-comment
/* eslint-disable react/style-prop-object */
import * as React from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import { connect } from 'react-redux';

import _isEqual from 'lodash.isequal';
import { selectTaskSolution } from 'Network/networkSlice';
import { StoreType } from 'store';

import CustomZoomControl from './CustomZoomControl';
import Gradient from './Gradient';
import IconsContainer from './Icons';
import { selectWellfieldData } from './mapSlice';
import PipesLayer from './PipesLayer';
import PopupContainer from './PopupContainer';
import { getCenter } from './service';
import { IMapProps, IMapState } from './types';

import './style.scss';

const MapContainer = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoidW1pYm96dXJvIiwiYSI6ImNpdmh5MzRkZTAwNm8ydG55bHJycHVuNzEifQ.VYG8irr9z7lyzJGGoZQf0A',
});

const containerStyle: React.CSSProperties = {
  height: '100vh',
  width: '100%',
  float: 'right',
};

class Map extends React.Component<IMapProps> {
  mapRef = React.createRef<any>();

  state: IMapState = {
    mapZoom: [11],
    mapCenter: [74.38369000426658, 62.994261416456375],
    showJunction: false,
  };

  componentDidUpdate(prevProp: IMapProps, prevState: IMapState) {
    const { taskSolution, wellfieldData } = this.props;
    const center = wellfieldData?.geometry_center;
    if (taskSolution) {
      const wellfieldUid = taskSolution?.task?.wellfield_uid;
      if (
        wellfieldUid &&
        wellfieldUid !== prevProp?.taskSolution?.task?.wellfield_uid
      ) {
        this.getWellfieldCenter(wellfieldUid);
      }
    }
    if (center) {
      if (!_isEqual(center, prevState.mapCenter)) {
        this.setState({
          mapCenter: center,
        });
      }
    }
  }

  getWellfieldCenter = async (uid: string) => {
    try {
      const { data } = await getCenter(uid);
      const mapCenter = data.geometry_center;
      if (mapCenter) {
        this.setState({ mapCenter });
      }
    } catch (err) {
      console.log(err);
    }
  };

  onZoomEnd: MapEvent = e => {
    const { showJunction } = this.state;
    const zoom = e.getZoom();
    const show = zoom >= 13;
    if (show !== showJunction) {
      this.setState({ showJunction: show });
    }
  };

  render() {
    const { mapCenter, mapZoom, showJunction } = this.state;

    return (
      <div className="map-container">
        <MapContainer
          ref={this.mapRef}
          style="mapbox://styles/umibozuro/cka0yxnql0av31irret0jtq4n"
          containerStyle={containerStyle}
          zoom={mapZoom}
          center={mapCenter}
          onZoomEnd={this.onZoomEnd}
        >
          <CustomZoomControl mapCenter={mapCenter} mapRef={this.mapRef} />
          <PipesLayer mapRef={this.mapRef} />
          <IconsContainer showJunction={showJunction} mapRef={this.mapRef} />
          <Gradient />
          <PopupContainer />
        </MapContainer>
      </div>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
  wellfieldData: selectWellfieldData(store),
});

export default connect(mapStateToProps)(Map);
