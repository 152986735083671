import React, { useState } from 'react';

import classnames from 'classnames';

import Input from '../../components/Input';
import { formatterForInt } from '../../utils';
import stringFormatter from '../../utils/stringFormatter';
import { ParameterType } from '../types';

interface ITaskSettingsInputProps {
  index: number;
  item: ParameterType;
  parameters: ParameterType[];
  setParameters(value: ParameterType[]): void;
}

const TaskSettingsInput: React.FC<ITaskSettingsInputProps> = ({
  index,
  item,
  parameters,
  setParameters,
}) => {
  const inputMode = item.error ? 'error' : 'normal';
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const spaces =
      stringFormatter(e.target.value).length - e.target.value.length;
    if (spaces > 0) {
      if (e.target.selectionStart) {
        setCursorPosition(e.target.selectionStart + spaces);
      }
    } else setCursorPosition(e.target.selectionStart);
    if (typeof setParameters === 'function') {
      const newParameters: ParameterType[] = [...parameters];
      const newItem: ParameterType = { ...item };
      newItem.value = e.target.value;
      newItem.error = false;
      newParameters[index] = newItem;
      setParameters(newParameters);
    }
  };
  const onBlur = () => {
    const newParameters: ParameterType[] = [...parameters];
    const newItem: ParameterType = { ...item };
    if (item.value) {
      const commaToDotValue = item.value.replace(',', '.');
      const valueWithoutSpaces = commaToDotValue.replace(/\s/g, '');
      if (typeof setParameters === 'function') {
        const newValue = parseFloat(valueWithoutSpaces);
        if (newValue) {
          newItem.value = formatterForInt
            .format(parseFloat(valueWithoutSpaces))
            .toString();
        } else {
          newItem.value = item.value;
          newItem.error = true;
          newItem.errorType = 'notNumber';
        }
      }
    } else {
      newItem.value = item.value;
      newItem.error = true;
      newItem.errorType = 'emptyValue';
    }
    newParameters[index] = newItem;
    setParameters(newParameters);
  };
  return (
    <div className="task-settings-row-parameter-column left">
      <span className="settings-dropdown-label task-settings-parameter-label">
        {item.label}
        {item.labelAddition}
      </span>
      <div className="task-settings-input-container relative">
        <Input
          value={stringFormatter(item.value)}
          onChange={onChangeValue}
          onBlur={onBlur}
          mode={inputMode}
          cursorPosition={cursorPosition}
        />
        <div
          className={classnames(
            'settings-tooltip task-settings-input-tooltip',
            item.errorType === 'emptyValue' ? 'empty-value' : 'not-number',
            item.error ? 'error' : '',
          )}
        >
          <span className="settings-tooltip-text">
            {item.errorType === 'emptyValue'
              ? 'Это поле не может быть пустым'
              : 'Введите числовое значение'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TaskSettingsInput;
