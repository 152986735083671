import * as React from 'react';

import classnames from 'classnames';

import { FormFieldPropsType, InputErrorType } from './types';

const FormField: React.FC<FormFieldPropsType> = React.memo(
  ({ type, placeholder, className, onChangeHandler, formValue, error }) => {
    const [value, setValue] = React.useState('');

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
      onChangeHandler(e, formValue);
      setValue(e.currentTarget.value);
    };

    const getMessageByErrorType = (errorType: keyof typeof InputErrorType) => {
      switch (errorType) {
        case 'emptyValue':
          return 'Поле не может быть пустым';
        case 'wrongValue':
          return 'Неверный логин или пароль';
        case 'notExist':
          return (
            <>
              Профиль не активен.
              <br />
              Обратитесь к администратору портала.
            </>
          );
        default:
          return '';
      }
    };

    return (
      <div
        className={classnames(
          'login-page-input-container relative',
          formValue,
          error.error && 'error',
        )}
      >
        <input
          value={value}
          type={type}
          className={className}
          placeholder={placeholder}
          onChange={onChange}
        />
        <div
          className={classnames(
            'login-tooltip',
            error.errorType === 'notExist' && 'not-exist-tooltip',
          )}
        >
          <span className="login-tooltip-text">
            {getMessageByErrorType(error.errorType)}
          </span>
        </div>
      </div>
    );
  },
);

export default FormField;
