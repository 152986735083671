import React, { useEffect, useRef } from 'react';

import classnames from 'classnames';

import { IInput } from './types';

import './style.scss';

const Input: React.FC<IInput> = ({
  className,
  mode = 'normal',
  value,
  onChange,
  readOnly = false,
  onBlur,
  cursorPosition,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef && inputRef.current) {
      if (cursorPosition) inputRef.current.selectionEnd = cursorPosition;
    }
  });
  return (
    <input
      className={classnames('input', [mode, className])}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      onBlur={onBlur}
      ref={inputRef}
    />
  );
};

export default Input;
