import * as React from 'react';
import { connect } from 'react-redux';

import { setPopupData } from 'Map/mapSlice';
import { HandlePopupType } from 'Map/types';

import IconsComponent from './IconsComponent';
import { IIconsContainerProps } from './types';
import { addImagesOnMap, icons, imageNames } from './utils';

class IconsContainer extends React.Component<IIconsContainerProps> {
  componentDidMount() {
    const map = this.props.mapRef.current.state.map;

    Object.keys(imageNames).forEach(addImagesOnMap(map));
  }

  handlePopup = ({ activeValue, popup }: HandlePopupType) => {
    this.props.setPopupData({
      popupType: popup,
      ...activeValue,
    });
  };

  render() {
    const { showJunction } = this.props;
    const map = this.props.mapRef.current.state.map;

    return (
      <>
        {icons.map((props, index) => (
          <IconsComponent
            key={index}
            {...props}
            handlePopup={this.handlePopup}
            map={map}
            showJunction={showJunction}
          />
        ))}
      </>
    );
  }
}

const mapDispatchToProps = { setPopupData };

export default connect(null, mapDispatchToProps)(IconsContainer);
