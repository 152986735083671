import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setWellfieldData } from 'Map/mapSlice';
import { StoreType } from 'store';

import LeftCol from 'components/LeftCol';
import ErrorPage from '../ErrorPage';
import Wrapper from '../Wrapper';
import Comparation from './Comparation';
import Dashboard from './Dashboard';
import LoadingPanel from './LoadingPanel';
import NetworkNotFound from './NetworkNotFound';
import { selectTaskSolution, setTaskSolution } from './networkSlice';
import NetworkTopRow from './NetworkTopRow';
import RestoreNetwork from './RestoreNetwork';
import { getSolution } from './service';
import Tables from './Tables';
import {
  NetworkPropsType,
  NetworkStateType,
  NodesById,
  WellsById,
} from './types';
import {
  comparationItems,
  composeById,
  composeByNodeId,
  dashboardItems,
  dashboardItemsAdapted,
} from './utils';

import './style.scss';

class Network extends React.Component<NetworkPropsType> {
  state: NetworkStateType = {
    items: dashboardItems(),
    adapted: false,
    comparation: comparationItems(null),
    loaded: false,
    notFoundError: false,
    internalServerError: false,
    restoreNetwork: false,
  };

  componentDidMount() {
    this.handleRoute();
  }

  componentDidUpdate(prevProps: NetworkPropsType) {
    const { pathname } = this.props.location;
    if (prevProps.location.pathname !== pathname) {
      if (!pathname.includes('restore')) {
        this.props.setTaskSolution({});
      }
      this.setState({
        loaded: false,
        items: dashboardItems(),
        notFoundError: false,
        internalServerError: false,
      });
      this.handleRoute();
    }
  }

  getTaskSolution = async (taskId: string) => {
    const {
      location: { pathname },
    } = this.props;
    const optimized = pathname.includes('optimized');
    const adapted = pathname.includes('adapted');

    try {
      const { data } = await getSolution({ taskId });

      if (adapted) {
        const { nodes, pipelines, wells } = data.wellfield_graph;

        const wellsById: WellsById = wells.reduce(composeById, {});
        const nodesById: NodesById = nodes.reduce(composeByNodeId, {});

        this.props.setTaskSolution({
          ...data,
          nodes,
          pipelines,
          wells,
          wellsById,
          nodesById,
        });
      } else {
        this.props.setTaskSolution(data);
      }

      this.props.setWellfieldData(null);

      const items = adapted
        ? dashboardItemsAdapted(data)
        : dashboardItems(data, optimized);
      const comparation = comparationItems(data as any);

      this.setState({
        adapted,
        items,
        comparation,
        loaded: true,
      });
    } catch (err) {
      this.setState({ loaded: true });
      const status: Response['status'] = err.response?.status;

      if (status === 500) {
        this.setState({ internalServerError: true });
      } else if ([404, 422].includes(status)) {
        this.setState({ notFoundError: true });
      }
    }
  };

  handleRoute() {
    const {
      location: { pathname },
      match: { params },
    } = this.props;
    const { id } = params as { id?: string };
    const restoreNetwork = pathname.includes('restore');
    this.setState({ restoreNetwork });

    if (id) this.getTaskSolution(id);
  }

  render() {
    const {
      items,
      adapted,
      comparation,
      loaded,
      notFoundError,
      internalServerError,
      restoreNetwork,
    } = this.state;

    if (internalServerError) return <ErrorPage type="internalServerError" />;

    return (
      <Wrapper>
        <LeftCol hideLink={notFoundError || restoreNetwork}>
          {notFoundError ? (
            <NetworkNotFound />
          ) : (
            <>
              <NetworkTopRow />
              {restoreNetwork ? (
                <RestoreNetwork />
              ) : (
                <div className="network-container">
                  <Dashboard mode="big" dashboardItems={items} />
                  {!adapted && <Comparation dashboardItems={comparation} />}
                  <Tables />
                </div>
              )}
            </>
          )}
        </LeftCol>
        {!loaded && <LoadingPanel />}
      </Wrapper>
    );
  }
}

const mapDispatchToProps = {
  setTaskSolution,
  setWellfieldData,
};

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Network));
