import React from 'react';

import classnames from 'classnames';

import { IUploadedFileProps } from './types';

import './wellfieldSettings.scss';

class UploadedFile extends React.Component<IUploadedFileProps> {
  onDelete = () => {
    if (typeof this.props.onFileDelete === 'function')
      this.props.onFileDelete(this.props.index);
  };

  render() {
    const { error, fileName } = this.props;
    return (
      <>
        <div
          className={classnames(
            'mode-table-file-panel relative',
            error.error && 'error',
          )}
        >
          <div className="file-panel-content">
            <div className="file-panel-excel-icon" />
            <span className="file-panel-content-text">{fileName}</span>
            <button
              className="file-panel-delete-icon"
              onClick={this.onDelete}
              aria-label="delete"
            />
          </div>
        </div>
      </>
    );
  }
}

export default UploadedFile;
