import * as React from 'react';
import { Popup } from 'react-mapbox-gl';
import { connect } from 'react-redux';

import { selectPopupData } from 'Map/mapSlice';
import { StoreType } from 'store';

import PopupComponent from './PopupComponent';
import { IPopupContainerProps } from './types';

const PopupContainer: React.FC<IPopupContainerProps> = ({ popupData }) => {
  if (!popupData) return null;
  const { coords, popupType } = popupData;

  return (
    <Popup style={{ zIndex: 99 }} coordinates={coords}>
      <PopupComponent type={popupType} />
    </Popup>
  );
};

const mapStateToProps = (store: StoreType) => ({
  popupData: selectPopupData(store),
});

export default connect(mapStateToProps)(PopupContainer);
