import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ValueType } from 'react-select';

import ErrorPage from 'ErrorPage';
import { TaskStatus } from 'generatedApiTypes';
import { getGatheringCenters, getTraces } from 'Settings/service';

import { selectTaskSolution, setTaskSolution } from '../Network/networkSlice';
import { CenterType, OptionType } from '../Settings/types';
import { StoreType } from '../store';
import ButtonsPanel from './ButtonsPanel';
import SelectGatheringCenterRow from './SelectGatheringCenterRow';
import { restoreNetworkWithAdaptation } from './service';
import SettingsRadioButton from './SettingsRadioButton';
import { ICalcSettingsProps, ICalcSettingsState } from './types';

import '../Settings/TaskSettings/taskSettings.scss';
import './style.scss';

class CalcSettings extends React.Component<ICalcSettingsProps> {
  state: ICalcSettingsState = {
    centerValue: null,
    centerToAdd: { name: '', uid: '', pressure: undefined },
    centersOptions: [],
    restoreExecuting: false,
    internalServerError: false,
  };

  componentDidMount() {
    if (this.props.taskSolution) {
      this.handleProps();
    }
  }

  componentDidUpdate(prevProps: ICalcSettingsProps) {
    if (!prevProps.taskSolution && this.props.taskSolution) {
      this.handleProps();
    }
  }

  onCancelClick = () => {
    const {
      match: { params },
    } = this.props;
    const { id } = params as { id?: string };
    this.props.history.push(`/adapted-network/${id}`);
  };

  onRestoreClick = async () => {
    const { centerToAdd, centerValue } = this.state;
    const { nameError, pressureError } = centerToAdd;
    if (!nameError && !pressureError && centerValue && centerToAdd.pressure) {
      const gcId = centerValue.value;
      const { task } = this.props.taskSolution;
      const params = {
        wellfield_uid: task?.wellfield_uid,
        gc_pressures: {
          [gcId]: parseFloat(centerToAdd.pressure.trim().replace(',', '.')),
        },
        calibration_task_id: task?.uid,
        files: task?.files,
        phys_chem: task?.phys_chem,
        init_node_states: [],
      };
      try {
        this.setState({ restoreExecuting: true });
        const { data } = await restoreNetworkWithAdaptation(params);
        await this.getTaskTrace(data?.uid);
      } catch (err) {
        this.setState({ restoreExecuting: false });
      }
    }
  };

  getTaskTrace = async (id: string) => {
    try {
      const { data } = await getTraces(id);
      const { status } = data;
      const statusOfCompletedTask: Array<keyof typeof TaskStatus> = [
        'SOLVED',
        'FAILED',
        'VALIDATION_ERROR',
      ];
      if (statusOfCompletedTask.includes(status)) {
        this.props.history.replace(`/network/${id}`);
        this.setState({ restoreExecuting: false });
      } else {
        setTimeout(() => {
          this.getTaskTrace(id);
        }, 30000);
      }
    } catch (err) {
      switch (err.response?.status) {
        case 404:
          return;
        case 500:
          this.setState({ internalServerError: true });
          break;
        default:
      }
    }
  };

  setCenterValue = (val: ValueType<OptionType, false>) => {
    this.setState({ centerValue: val });
  };

  setCenterToAdd = (val: CenterType) => {
    this.setState({ centerToAdd: val });
  };

  async handleProps() {
    try {
      const wellfieldUid = this.props.taskSolution.task?.wellfield_uid;
      const { data } = await getGatheringCenters({ wellfieldUid });
      const centersOptions = data.map(option => ({
        value: option.uid,
        label: option.name,
      }));
      const { centerToAdd } = this.state;
      const gcId = this.props.taskSolution?.task?.gathering_centers[0];
      const centerValue = centersOptions.filter(el => el.value === gcId)[0];
      const pressure = this.props.taskSolution?.gc_pressure?.toString();

      this.setState({
        centersOptions,
        centerValue,
        centerToAdd: {
          ...centerToAdd,
          pressure,
        },
      });
    } catch (err) {
      console.log(err, 'err');
    }
  }

  render() {
    const {
      centerValue,
      centerToAdd,
      centersOptions,
      restoreExecuting,
      internalServerError,
    } = this.state;

    if (internalServerError) return <ErrorPage type="internalServerError" />;

    return (
      <div className="settings-container">
        <div className="settings-content-container task-settings-container">
          <span className="settings-subtitle">Настройки расчета</span>
          <div className="settings-panel">
            <SelectGatheringCenterRow
              centerValue={centerValue}
              setCenterValue={this.setCenterValue}
              centerToAdd={centerToAdd}
              setCenterToAdd={this.setCenterToAdd}
              centersOptions={centersOptions}
            />
            <span className="calc-settings-method-label settings-dropdown-label">
              Метод узлового анализа
            </span>
            <div className="calc-settings-radio-row">
              <SettingsRadioButton
                id="task-settings-first-radio"
                value="1"
                label="Классические методы оптимизации"
                name="taskSettings"
              />
              <SettingsRadioButton
                id="task-settings-second-radio"
                value="2"
                label="Мультиагентные методы оптимизации"
                name="taskSettings"
                disabled
              />
            </div>
          </div>
        </div>
        <ButtonsPanel
          restoreExecuting={restoreExecuting}
          onCancelClick={this.onCancelClick}
          onRestoreClick={this.onRestoreClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  setTaskSolution,
};

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CalcSettings));
