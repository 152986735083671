import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import classnames from 'classnames';

import { ReactComponent as Logo } from '../assets/images/logo-login.svg';
import Button from '../components/Button';
import { handleStorage } from '../services/handleStorage';
import { setAuth } from './authSlice';
import FormField from './FormField';
import { login } from './service';
import { LoginStateType } from './types';

import './style.scss';

export type LoginPropsType = RouteComponentProps & {
  setAuth: typeof setAuth;
};

class Login extends React.Component<LoginPropsType> {
  state: LoginStateType = {
    password: '',
    passwordError: { error: false, errorType: 'emptyValue' },
    username: '',
    usernameError: { error: false, errorType: 'emptyValue' },
  };

  tryToLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const { password, username } = this.state;
    if (this.validateFormValues(password, username)) {
      try {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        const {
          data: { access_token: token },
        } = await login(formData);
        handleStorage.setToken(token);
        this.props.setAuth(!!token);
        this.props.history.push('/');
      } catch (err) {
        if (err.response?.status === 401) {
          if (
            err.response?.data?.user_message === 'Неверный логин или пароль'
          ) {
            this.setState({
              usernameError: { error: true, errorType: 'wrongValue' },
              passwordError: { error: true, errorType: 'wrongValue' },
            });
          } else if (
            err.response?.data?.user_message === 'Неактивный пользователь'
          ) {
            this.setState({
              usernameError: { error: true, errorType: 'notExist' },
            });
          }
        }
      }
    } else {
      if (!username) {
        this.setState({
          usernameError: { error: true, errorType: 'emptyValue' },
        });
      }
      if (!password) {
        this.setState({
          passwordError: { error: true, errorType: 'emptyValue' },
        });
      }
    }
  };

  validateFormValues = (password: string, username: string): boolean =>
    !!(password && username);

  onChangeHandler = (
    e: React.FormEvent<HTMLInputElement>,
    formValue: 'password' | 'username',
  ) => {
    this.setState({
      [formValue]: e.currentTarget.value,
    });
    switch (formValue) {
      case 'username':
        this.setState({ usernameError: { error: false, errorType: 0 } });
        break;
      case 'password':
        this.setState({ passwordError: { error: false, errorType: 0 } });
        break;
      default:
        break;
    }
  };

  render() {
    const { usernameError, passwordError } = this.state;
    return (
      <div className="login-page-container">
        <form onSubmit={this.tryToLogin} className="login-page-content">
          <div className="login-page-logo-wrapper">
            <div className="logo-img-wrapper">
              <Logo />
            </div>
            <div className="logo-text-wrapper">
              <span className="logo-text">
                Оптимизация режимов работы трубопроводов
              </span>
            </div>
          </div>
          <FormField
            onChangeHandler={this.onChangeHandler}
            formValue="username"
            className={classnames('login-page-input')}
            placeholder="Логин"
            error={usernameError}
          />
          <FormField
            onChangeHandler={this.onChangeHandler}
            formValue="password"
            type="password"
            className={classnames('login-page-input password-input')}
            placeholder="Пароль"
            error={passwordError}
          />
          <div className="login-page-button-wrapper">
            <Button className="login-page-button">Войти</Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setAuth,
};

export default connect(null, mapDispatchToProps)(withRouter(Login));
