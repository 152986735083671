import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ValueType } from 'react-select';

import dayjs from 'dayjs';
import { TaskStatus } from 'generatedApiTypes';
import { setWellfieldData } from 'Map/mapSlice';
import { selectTaskSolution } from 'Network/networkSlice';

import DialogWindow from '../components/DialogWindow';
import LeftCol from '../components/LeftCol';
import LeftColSubTopRow from '../components/LeftCol/LeftColSubTopRow';
import ErrorPage from '../ErrorPage';
import { StoreType } from '../store';
import { formatter } from '../utils';
import Wrapper from '../Wrapper';
import ButtonsPanel from './ButtonsPanel';
import initParameters from './initParameters';
import OverviewButton from './OverviewPanel/OverviewButton';
import OverviewPanel from './OverviewPanel/OverviewPanel';
import {
  adaptNetwork,
  getDateTimeOptions,
  getGatheringCenters,
  getOverviewInfo,
  getTraces,
  getWellfieldGraph,
  getWellfields,
  uploadFile,
} from './service';
import SettingsFrame from './SettingsFrame';
import {
  CenterType,
  DropzoneError,
  ISettingsProps,
  ISettingsState,
  OptionType,
  OverviewInfoType,
  ParameterType,
  UploadedFileType,
  WellfieldError,
  WellfieldOption,
} from './types';

import './settings.scss';

class Settings extends React.Component<ISettingsProps> {
  state: ISettingsState = {
    frameIx: 0,
    wellfieldValue: { option: null, link: '' },
    wellfieldValueError: { error: false },
    uploadedFiles: [],
    dropzoneError: { error: false, errorsList: [] },
    centerValue: null,
    centerToAdd: { name: '', uid: '', pressure: '0' },
    centers: [],
    centersOptions: [],
    dateTimeOptions: [],
    dateTimeValue: null,
    dateTimeValueError: { error: false },
    parameters: initParameters,
    adaptationMethod: 'NUMDES',
    wellfieldOptions: [],
    initCentersOptions: [],
    overviewInfo: {
      techmodeWellsCount: 0,
      wellsTablesCount: 0,
      dfAvg: 0,
      dfMax: 0,
      dpAvg: 0,
      dpMax: 0,
      dFluidFlowAvg: 0,
      dFluidFlowMax: 0,
      dGasFlowAvg: 0,
      dGasFlowMax: 0,
      dOilFlowAvg: 0,
      dOilFlowMax: 0,
    },
    wellfieldSettingsStepExecuting: false,
    taskSettingsStepExecuting: false,
    internalServerError: false,
    wellfieldDialogShown: false,
    overviewButtonIsActive: false,
  };

  async componentDidMount(): Promise<any> {
    this.getWellfieldOptions();
  }

  getWellfieldOptions = async () => {
    try {
      const options = await getWellfields();
      const wellfieldOptions: WellfieldOption[] = [];
      if (options.data) {
        options.data.forEach(record => {
          wellfieldOptions.push({
            option: { value: record.uid, label: record.name },
            link: record.iskra_url,
          });
        });
      }
      wellfieldOptions.push({
        option: { value: 'new', label: 'Добавить месторождение' },
        link: '',
      });
      this.setState({ wellfieldOptions }, this.restoreSettings);
    } catch (err) {
      if (err.response?.status === 500) {
        this.setState({ internalServerError: true });
      }
    }
  };

  restoreSettings = async () => {
    if (this.props.taskSolution) {
      this.restoreWellfield();
      this.restoreFiles();
      this.restoreTaskSettings();
    }
  };

  restoreWellfield = async () => {
    const { wellfieldOptions } = this.state;
    const wellfield = wellfieldOptions.find(
      item => item.option.value === this.props.taskSolution.task?.wellfield_uid,
    );
    if (wellfield) {
      this.setState({ wellfieldValue: wellfield });
      await this.gatheringCentersRequest(
        wellfield.option,
        this.restoreGatheringCenters,
      );
    }
  };

  restoreFiles = () => {
    const files: UploadedFileType[] = [
      { uid: '', name: '', file: null, error: { error: false } },
      { uid: '', name: '', file: null, error: { error: false } },
    ];
    const entries = this.props.taskSolution.task?.files?.entries();
    if (!entries) return;
    for (const [index, file] of entries) {
      files[index].uid = file.uid;
      files[index].name = file.name;
      if (file.name.split('.')[0] === 'ТехРежим') {
        this.setState({ techmodeFileUid: file.uid });
      } else if (file.name.split('.')[0] === 'Скважины') {
        this.setState({ wellsTablesFileUid: file.uid });
      }
    }
    this.setState({ uploadedFiles: files });
  };

  restoreGatheringCenters = () => {
    const { centersOptions } = this.state;
    const gcPressures = this.props.taskSolution.task?.gc_pressures;
    if (!gcPressures) return;
    const centers = Object.entries(this.props.taskSolution.task?.gc_pressures);
    const restoredCenters: CenterType[] = [];
    for (const [center, value] of centers) {
      const foundCenter: OptionType | undefined = centersOptions.find(
        item => item.value === center,
      );
      if (foundCenter) {
        restoredCenters.push({
          pressure: (value as number).toString(),
          name: foundCenter.label,
          uid: foundCenter.value,
        });
        const currentOptions = [...centersOptions];
        const ix = currentOptions.findIndex(obj => {
          return (obj as OptionType).value === foundCenter.value;
        });
        if (ix > -1) {
          currentOptions.splice(ix, 1);
        }
        this.setCentersOptions(currentOptions);
      }
    }
    this.setState({ centers: restoredCenters });
  };

  restoreTaskSettings = async () => {
    const { taskSolution } = this.props;
    const { parameters } = this.state;
    const restoredParameters = [...parameters];
    const physChemFields = [
      'roughness_mm',
      'oil_density_kg_m3',
      'water_density_kg_m3',
      'gas_rel_density',
      'oil_viscosity_20_cp',
      'oil_viscosity_50_cp',
      'fluid_temperature',
    ];
    physChemFields.forEach((value, index) => {
      const physChem = taskSolution.task?.phys_chem;
      if (physChem) {
        restoredParameters[index].value = formatter.format(
          physChem[value]?.toString(),
        );
      }
    });

    this.setState({
      parameters: restoredParameters,
      taskCheckbox1: taskSolution.task?.use_aspo,
      taskCheckbox2: taskSolution.task?.use_inclination,
    });
  };

  onNextBtnClick = async () => {
    const {
      frameIx,
      wellfieldValue,
      uploadedFiles,
      parameters,
      centers,
      centerToAdd,
    } = this.state;
    const techModeFile = uploadedFiles.find(
      item => item.name.split('.')[0] === 'ТехРежим',
    );
    const wellsFile = uploadedFiles.find(
      item => item.name.split('.')[0] === 'Скважины',
    );
    const oisPipeFile = uploadedFiles.find(
      item => item.name.split('.')[0] === 'Фактические измерения из OIS Pipe',
    );
    switch (frameIx) {
      case 0: {
        if (!wellfieldValue) {
          this.setWellfieldValueError({ error: true, errorType: 'notChosen' });
        }
        const errorsList: any = [];
        if (!techModeFile) errorsList.push('noTechModeFile');
        if (!wellsFile) errorsList.push('noWellsFile');
        if (!oisPipeFile) errorsList.push('noOISPipeFile');
        if (!techModeFile || !wellsFile || !oisPipeFile) {
          this.setDropzoneError({ errorsList, error: true });
        } else if (wellfieldValue) {
          this.applyWellfieldSettings();
        }
        return;
      }
      case 1: {
        let errors = false;
        if (centers.length < 1) {
          const center = { ...centerToAdd };
          center.nameError = true;
          center.nameErrorType = 'noCentersAdded';
          this.setCenterToAdd(center);
          errors = true;
        }
        centers.forEach(item => {
          if (item.pressureError) errors = true;
        });
        if (!errors) this.setFrameIx(frameIx + 1);
        return;
      }
      case 2: {
        let isErrors = false;
        parameters.forEach(item => {
          if (item.error) {
            isErrors = true;
          }
        });
        if (!isErrors) this.setFrameIx(frameIx + 1);
        break;
      }
      default:
    }
  };

  uploadFiles = async () => {
    let errors = false;
    const { uploadedFiles, wellfieldValue } = this.state;
    const changedFiles = [...uploadedFiles];
    for (const [index, file] of uploadedFiles.entries()) {
      if (file.uid === '') {
        try {
          const formData = new FormData();
          formData.append(
            'wellfield_uid',
            (wellfieldValue.option as OptionType).value,
          );
          if (file.file) {
            formData.append('file', file.file);
          }
          const response = await uploadFile(formData);
          changedFiles[index].uid = response?.data?.uid;
          changedFiles[index].name = response?.data?.name;
        } catch (err) {
          errors = true;
          switch (err.response?.status) {
            case 400:
              changedFiles[index].error.error = true;
              if (
                err.response?.data?.user_message === 'Некорректное имя файла.'
              ) {
                changedFiles[index].error.errorType = 'wrongName';
              } else {
                changedFiles[index].error.errorType = 'wrongFile';
              }
              break;
            case 500:
              this.setState({ internalServerError: true });
              break;
            default:
          }
        }
      }
    }
    if (errors) {
      this.setDropzoneError({ error: true, errorsList: ['fileError'] });
      throw new Error();
    }
    this.setState({ uploadedFiles: changedFiles });
  };

  applyWellfieldSettings = async () => {
    let isErrors = false;
    this.setState({ wellfieldSettingsStepExecuting: true });
    try {
      await this.uploadFiles();
      await this.overviewInfoRequest();
      await this.dateTimeOptionsRequest();
    } catch {
      isErrors = true;
    }
    if (!isErrors) this.setFrameIx(this.state.frameIx + 1);
    this.setState({ wellfieldSettingsStepExecuting: false });
  };

  dateTimeOptionsRequest = async () => {
    const { uploadedFiles } = this.state;
    const oisPipeFile = uploadedFiles.find(
      item => item.name.split('.')[0] === 'Фактические измерения из OIS Pipe',
    );
    if (oisPipeFile) {
      try {
        const response = await getDateTimeOptions(oisPipeFile.uid);
        const dateTimeOptions: OptionType[] = [];
        if (response.data) {
          response.data.forEach(record => {
            dateTimeOptions.push({
              value: record,
              label: dayjs(record).format('DD.MM.YY hh:mm:ss'),
            });
          });
          this.setState({ dateTimeOptions });
        }
      } catch (err) {
        switch (err.response?.status) {
          case 400:
            const changedFiles = [...uploadedFiles];
            for (const [index, file] of changedFiles.entries()) {
              if (
                file.name.split('.')[0] === 'Фактические измерения из OIS Pipe'
              ) {
                changedFiles[index].error.error = true;
                changedFiles[index].error.errorType = 'wrongFile';
              }
            }
            this.setState({ uploadedFiles: changedFiles });
            break;
          case 500:
            this.setState({ internalServerError: true });
            break;
          default:
            break;
        }
        throw new Error();
      }
    }
  };

  overviewInfoRequest = async () => {
    const { uploadedFiles } = this.state;
    const techModeFile = uploadedFiles.find(
      item => item.name.split('.')[0] === 'ТехРежим',
    );
    const wellsFile = uploadedFiles.find(
      item => item.name.split('.')[0] === 'Скважины',
    );
    if (wellsFile && techModeFile) {
      try {
        const info: OverviewInfoType = { ...this.state.overviewInfo };
        const response = await getOverviewInfo(techModeFile.uid, wellsFile.uid);
        if (response.data) {
          info.techmodeWellsCount = response.data.techmode_wells_count;
          info.wellsTablesCount = response.data.wells_tables_count;
          info.dpAvg = response.data.dp_avg;
          info.dpMax = response.data.dp_max;
          info.dfAvg = response.data.df_avg;
          info.dfMax = response.data.df_max;
          info.dFluidFlowAvg = response.data.d_fluid_flow_avg.toFixed(2);
          info.dFluidFlowMax = response.data.d_fluid_flow_max.toFixed(2);
          info.dOilFlowAvg = response.data.d_oil_flow_avg.toFixed(2);
          info.dOilFlowMax = response.data.d_oil_flow_max.toFixed(2);
          info.dGasFlowAvg = response.data.d_gas_flow_avg.toFixed(2);
          info.dGasFlowMax = response.data.d_gas_flow_max.toFixed(2);
          this.setState({ overviewInfo: info });
        }
      } catch (err) {
        switch (err.response?.status) {
          case 400:
            const changedFiles = [...uploadedFiles];
            for (const [index, file] of changedFiles.entries()) {
              if (
                file.name.split('.')[0] === 'ТехРежим' ||
                file.name.split('.')[0] === 'Скважины'
              ) {
                changedFiles[index].error.error = true;
                changedFiles[index].error.errorType = 'notCorrespondingFile';
              }
            }
            this.setState({ uploadedFiles: changedFiles });
            break;
          case 500:
            this.setState({ internalServerError: true });
            break;
          default:
            break;
        }
        throw new Error();
      }
    }
  };

  onAdaptClick = async () => {
    const {
      centers,
      parameters,
      uploadedFiles,
      wellfieldValue,
      dateTimeValue,
      adaptationMethod,
    } = this.state;
    let isErrors = false;
    if (!dateTimeValue) {
      this.setState({
        dateTimeValueError: { error: true, errorType: 'notChosen' },
      });
      isErrors = true;
    }
    if (!isErrors) {
      this.setState({ taskSettingsStepExecuting: true });
      try {
        const response = await adaptNetwork({
          uploadedFiles,
          centers,
          parameters,
          adaptationMethod,
          wellfieldUid: (wellfieldValue.option as OptionType).value,
          date: dateTimeValue?.value,
        });

        if (response.data.uid) {
          await this.getTaskTrace(response.data.uid);
        }
      } catch (err) {
        switch (err.response?.status) {
          case 404:
            return;
          case 500:
            this.setState({ internalServerError: true });
            break;
          default:
        }
      }
    }
  };

  getTaskTrace = async (id: string) => {
    try {
      const { data } = await getTraces(id);
      const { status } = data;
      const statusOfCompletedTask: Array<keyof typeof TaskStatus> = [
        'SOLVED',
        'FAILED',
        'VALIDATION_ERROR',
      ];
      if (statusOfCompletedTask.includes(status)) {
        this.setState({ taskSettingsStepExecuting: false });
        this.props.history.replace(`/adapted-network/${id}`);
      } else {
        setTimeout(() => {
          this.getTaskTrace(id);
        }, 30000);
      }
    } catch (err) {
      switch (err.response?.status) {
        case 404:
          return;
        case 500:
          this.setState({ internalServerError: true });
          break;
        default:
      }
    }
  };

  onPrevBtnClick = () => {
    this.setFrameIx(this.state.frameIx - 1);
  };

  setFrameIx = (ix: number) => {
    this.setState({ frameIx: ix });
  };

  setWellfieldValue = async (value: ValueType<OptionType, false>) => {
    const { wellfieldOptions } = this.state;
    const wellfield = wellfieldOptions.find(
      item => item.option.value === (value as OptionType).value,
    );
    this.gatheringCentersRequest(value, null);
    this.setState({ wellfieldValue: { option: value, link: wellfield?.link } });
    const { data } = await getWellfieldGraph((value as OptionType).value);
    this.props.setWellfieldData(data);
  };

  gatheringCentersRequest = async (
    wellfieldValue: ValueType<OptionType, false>,
    restoreCenters,
  ) => {
    try {
      const options = await getGatheringCenters({
        wellfieldUid: (wellfieldValue as OptionType).value,
      });
      const initCentersOptions: OptionType[] = [];
      if (options.data) {
        for (const option of options.data) {
          initCentersOptions.push({ value: option.uid, label: option.name });
        }
        this.setState(
          {
            initCentersOptions,
            centersOptions: initCentersOptions,
            centers: [],
          },
          restoreCenters,
        );
      }
    } catch (err) {
      switch (err.response?.status) {
        case 404:
        case 422:
          this.setWellfieldValueError({ error: true, errorType: 'notFound' });
          return;
        case 500:
          this.setState({ internalServerError: true });
          return;
        default:
          this.setWellfieldValueError({ error: true });
      }
    }
  };

  setWellfieldValueError = (val: WellfieldError) => {
    this.setState({ wellfieldValueError: val });
  };

  setUploadedFiles = (val: UploadedFileType[]) => {
    this.setState({ uploadedFiles: val });
  };

  setDropzoneError = (error: DropzoneError) => {
    this.setState({ dropzoneError: error });
  };

  setCenterToAdd = (val: CenterType) => {
    this.setState({ centerToAdd: val });
  };

  setCenterValue = (val: ValueType<OptionType, false>) => {
    this.setState({ centerValue: val });
  };

  setCenters = (val: CenterType[]) => {
    this.setState({ centers: val });
  };

  setCentersOptions = (val: ValueType<OptionType, false>[]) => {
    this.setState({ centersOptions: val });
  };

  setParameters = (val: ParameterType[]) => {
    this.setState({ parameters: val });
  };

  setAdaptationMethod = (val: string) => {
    this.setState({ adaptationMethod: val });
  };

  setDateTimeValue = (val: ValueType<OptionType, false>) => {
    this.setState({ dateTimeValue: val });
  };

  showWellfieldDialog = () => {
    this.setState({ wellfieldDialogShown: true });
  };

  closeWellfieldDialog = () => {
    this.setState({ wellfieldDialogShown: false });
  };

  toggleOverviewButton = () => {
    const { overviewButtonIsActive } = this.state;
    this.setState({ overviewButtonIsActive: !overviewButtonIsActive });
  };

  render() {
    const {
      frameIx,
      wellfieldValue,
      wellfieldSettingsStepExecuting,
      taskSettingsStepExecuting,
      wellfieldOptions,
      wellfieldValueError,
      uploadedFiles,
      dropzoneError,
      centersOptions,
      centerToAdd,
      initCentersOptions,
      centerValue,
      centers,
      parameters,
      overviewInfo,
      internalServerError,
      dateTimeOptions,
      dateTimeValue,
      dateTimeValueError,
      wellfieldDialogShown,
      overviewButtonIsActive,
      adaptationMethod,
    } = this.state;
    return (
      <>
        {internalServerError ? (
          <ErrorPage type="internalServerError" />
        ) : (
          <Wrapper>
            {wellfieldDialogShown && (
              <DialogWindow
                closeWindow={this.closeWellfieldDialog}
                getWellfieldOptionsList={this.getWellfieldOptions}
              />
            )}
            <OverviewButton
              panelIsActive={overviewButtonIsActive}
              toggleOverviewPanel={this.toggleOverviewButton}
            />
            <LeftCol>
              <LeftColSubTopRow>
                <div className="settings-top-row">
                  <span className="settings-title">Задание входных данных</span>
                </div>
              </LeftColSubTopRow>
              <div className="settings-container">
                <SettingsFrame
                  index={frameIx}
                  wellfieldValue={wellfieldValue}
                  setWellfieldValue={this.setWellfieldValue}
                  wellfieldValueError={wellfieldValueError}
                  setWellfieldValueError={this.setWellfieldValueError}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={this.setUploadedFiles}
                  dropzoneError={dropzoneError}
                  setDropzoneError={this.setDropzoneError}
                  centerToAdd={centerToAdd}
                  setCenterToAdd={this.setCenterToAdd}
                  centerValue={centerValue as OptionType}
                  setCenterValue={this.setCenterValue}
                  centers={centers}
                  setCenters={this.setCenters}
                  initOptions={initCentersOptions}
                  centersOptions={centersOptions}
                  setCentersOptions={this.setCentersOptions}
                  parameters={parameters}
                  setParameters={this.setParameters}
                  setAdaptationMethod={this.setAdaptationMethod}
                  adaptationMethod={adaptationMethod}
                  wellfieldOptions={wellfieldOptions}
                  dateTimeOptions={dateTimeOptions}
                  dateTimeValue={dateTimeValue}
                  setDateTimeValue={this.setDateTimeValue}
                  dateTimeValueError={dateTimeValueError}
                  showWellfieldDialog={this.showWellfieldDialog}
                />
                <ButtonsPanel
                  frameIx={frameIx}
                  wellfieldSettingsStepExecuting={
                    wellfieldSettingsStepExecuting
                  }
                  taskSettingsStepExecuting={taskSettingsStepExecuting}
                  onPrevBtnClick={this.onPrevBtnClick}
                  onNextBtnClick={this.onNextBtnClick}
                  onAdaptClick={this.onAdaptClick}
                />
              </div>
            </LeftCol>
            {overviewButtonIsActive && (
              <OverviewPanel
                index={frameIx}
                wellfieldValue={wellfieldValue}
                centers={centers}
                overviewInfo={overviewInfo}
              />
            )}
          </Wrapper>
        )}
      </>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
});

const mapDispatchToProps = {
  setWellfieldData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Settings));
