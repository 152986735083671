import * as React from 'react';

import { formatter } from 'utils';

export default [
  {
    value: formatter.format(0.1).toString(),
    label: 'Шероховатость',
    labelAddition: null,
    error: false,
  },
  {
    value: '859',
    label: 'Плотность нефти, ',
    labelAddition: (
      <span className="relative">
        кг/м<span className="power">3</span>
      </span>
    ),
    error: false,
  },
  {
    value: '1000',
    label: 'Плотность воды, ',
    labelAddition: (
      <span className="relative">
        кг/м<span className="power">3</span>
      </span>
    ),
    error: false,
  },
  {
    value: formatter.format(0.7).toString(),
    label: 'Отн. плотность газа',
    labelAddition: null,
    error: false,
  },
  {
    value: '4',
    label: 'Вязкость нефти при 20',
    labelAddition: <>&deg;C</>,
    error: false,
  },
  {
    value: '2',
    label: 'Вязкость нефти при 50',
    labelAddition: <>&deg;C</>,
    error: false,
  },
  {
    value: '20',
    label: 'Температура смеси, ',
    labelAddition: <>&deg;C</>,
    error: false,
  },
];
