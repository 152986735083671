import React from 'react';

import Button from '../components/Button';

interface IButtonsPanelProps {
  restoreExecuting: boolean;
  onCancelClick(): void;
  onRestoreClick(): void;
}

const ButtonsPanel: React.FC<IButtonsPanelProps> = ({
  restoreExecuting,
  onCancelClick,
  onRestoreClick,
}) => {
  const restoreButtonMode = restoreExecuting ? 'execution' : 'primary';
  return (
    <div className="settings-container-button-panel">
      <div className="button-panel-wrapper">
        <Button
          className="settings-container-prev-btn"
          mode="secondary"
          onClick={onCancelClick}
        >
          Отмена
        </Button>
        <Button
          className="settings-container-next-btn"
          mode={restoreButtonMode}
          onClick={onRestoreClick}
        >
          {restoreButtonMode === 'execution'
            ? 'Восстановлие...'
            : 'Восстановить'}
        </Button>
      </div>
    </div>
  );
};

export default ButtonsPanel;
