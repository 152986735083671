import * as React from 'react';
import { connect } from 'react-redux';

import { selectPopupData, setPopupData } from 'Map/mapSlice';
import { StoreType } from 'store';

import { formatter } from 'utils';
import PopupTopRow from './PopupTopRow';
import { IPopupPipeRrops } from './types';

const PopupPipe: React.FC<IPopupPipeRrops> = ({
  popupData,
  updatePopupData,
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    ref.current?.focus();
  }, []);

  const hidePopup = () => updatePopupData(null);

  const {
    length_m: length,
    diameter_mm: diameter,
    wall_thickness_mm: thickness,
    aspo_mm: aspo,
    start_node_name: startNode,
    end_node_name: endNode,
    pressure_calc: pressureCalc,
    pressure_fact: pressureFact,
    dp_abs: dpAbs,
    dp_rel: dpRel,
  } = popupData;

  return (
    <div tabIndex={-1} onBlur={hidePopup} ref={ref}>
      <PopupTopRow firstTitle={startNode} secondTitle={endNode} />
      <table>
        <tbody>
          <tr className="popup-table-row">
            <td className="popup-table-col">
              <div className="popup-row__sub-row">
                <p className="popup-val-dem">Длина, мм</p>
                <p className="popup-title">{formatter.format(length)}</p>
              </div>
              <div className="popup-row__sub-row">
                <p className="popup-val-dem">Внешний диаметр, мм</p>
                <p className="popup-title">{formatter.format(diameter)}</p>
              </div>
              <div className="popup-row__sub-row">
                <p className="popup-val-dem">Толщина стенки, мм</p>
                <p className="popup-title">{formatter.format(thickness)}</p>
              </div>
            </td>
            <td>
              <div className="popup-row__sub-row">
                <p className="popup-val-dem">АСПО, мм</p>
                <p className="popup-title">{formatter.format(aspo)}</p>
              </div>
            </td>
          </tr>
          {pressureCalc && (
            <tr className="popup-table-row">
              <td className="popup-table-col">
                <div className="popup-row__sub-row">
                  <p className="popup-val-dem">Pр, атм</p>
                  <p className="popup-title">
                    {formatter.format(pressureCalc)}
                  </p>
                </div>
                <div className="popup-row__sub-row">
                  <p className="popup-val-dem">Pф, атм</p>
                  <p className="popup-title">
                    {formatter.format(pressureFact)}
                  </p>
                </div>
              </td>
              <td>
                <div className="popup-row__sub-row">
                  <p className="popup-val-dem">ΔP, атм</p>
                  <p className="popup-title">{formatter.format(dpAbs)}</p>
                </div>
                <div className="popup-row__sub-row">
                  <p className="popup-val-dem">δP, %</p>
                  <p className="popup-title">{formatter.format(dpRel)}</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store: StoreType) => ({
  popupData: selectPopupData(store),
});

const mapDispatchToProps = {
  updatePopupData: setPopupData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupPipe);
