import * as React from 'react';

class LeftColSubTopRow extends React.Component {
  static leftColRole: string;

  render() {
    return <>{this.props.children}</>;
  }
}

LeftColSubTopRow.leftColRole = 'SubTopRow';

export default LeftColSubTopRow;
