import React, { createRef } from 'react';

import Input from '../../components/Input';
import { CenterType } from '../types';

import './gatheringCenterSettings.scss';

interface ICenterRowProps {
  centerIx: number;
  item: CenterType;
  centers: CenterType[];
  setCenters(value: CenterType[]): void;
  onDeleteCenter(index: number, center: string): void;
  panRef: React.RefObject<HTMLDivElement>;
  scrolled: boolean;
  added: boolean;
}

interface ICenterRowState {
  cursorPosition: number | null;
  top: boolean;
}

class GatheringCenterRow extends React.Component<ICenterRowProps> {
  tooltipRef = createRef<HTMLDivElement>();

  state: ICenterRowState = {
    cursorPosition: null,
    top: false,
  };

  componentDidMount(): void {
    this.checkTooltip();
  }

  componentDidUpdate(prevProps: ICenterRowProps): void {
    if (
      prevProps.scrolled !== this.props.scrolled ||
      prevProps.added !== this.props.added
    ) {
      this.setState({ top: false }, this.checkTooltip);
    }
  }

  checkTooltip = () => {
    if (this.tooltipRef.current && this.props.panRef.current) {
      const tooltipRect = this.tooltipRef.current.getBoundingClientRect();
      const panelRect = this.props.panRef.current.getBoundingClientRect();
      const styles = getComputedStyle(this.props.panRef.current);
      const panelMaxBottom = panelRect.top + parseInt(styles.maxHeight, 10);
      if (
        tooltipRect.bottom > panelMaxBottom ||
        tooltipRect.bottom >
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.setState({ top: true });
      }
    }
  };

  onDeleteHandler = () => {
    if (typeof this.props.onDeleteCenter === 'function')
      this.props.onDeleteCenter(this.props.centerIx, this.props.item.name);
  };

  render() {
    return (
      <div className="gathering-center-row displaying-row">
        <div className="gathering-center-column left">
          <div className="settings-dropdown-container gathering-centers-dropdown">
            <Input value={this.props.item.name} readOnly />
          </div>
        </div>
        <button
          className="gathering-center-row-btn delete-btn"
          onClick={this.onDeleteHandler}
        >
          Удалить
        </button>
      </div>
    );
  }
}

export default GatheringCenterRow;
