import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectPopupData, setPopupData } from 'Map/mapSlice';
import { StoreType } from 'store';

import { formatter } from 'utils';
import { IPopupNodePropsType } from './types';

const PopupNode: React.FC<IPopupNodePropsType> = ({
  updatePopupData,
  popupData,
  history,
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    ref.current?.focus();
  }, []);

  const hidePopup = () => updatePopupData(null);

  const {
    name,
    pressure_atm: pressureAtm,
    oil_flow_rate_ton_day: oilFlow,
    gas_flow_rate_m3_day: gasFlow,
    fluid_flow_rate_ton_day: fluidFlow,
  } = popupData;

  const {
    location: { pathname },
  } = history;

  const isSettings = pathname.includes('settings');

  return (
    <div tabIndex={-1} onBlur={hidePopup} ref={ref}>
      <div className="popup-row">
        <div className="popup-row__sub-row">
          <p className="popup-title">{name}</p>
        </div>
        {!isSettings && (
          <div className="popup-row__sub-row">
            <p className="popup-val-dem">P, атм</p>
            <p className="popup-title">{formatter.format(pressureAtm)}</p>
          </div>
        )}
      </div>
      {!isSettings && (
        <div className="popup-row">
          <div className="popup-row__sub-row">
            <p className="popup-val-dem">
              Qж,{' '}
              <span className="relative">
                м<span className="index">3</span>
              </span>{' '}
              /сут
            </p>
            <p className="popup-title">{formatter.format(fluidFlow)}</p>
          </div>
          <div className="popup-row__sub-row">
            <p className="popup-val-dem">Qн, т/сут</p>
            <p className="popup-title">{formatter.format(oilFlow)}</p>
          </div>
          <div className="popup-row__sub-row">
            <p className="popup-val-dem">
              Qг, тыс.{' '}
              <span className="relative">
                м<span className="index">3</span>
              </span>{' '}
              /сут
            </p>
            <p className="popup-title">{formatter.format(gasFlow)}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store: StoreType) => ({
  popupData: selectPopupData(store),
});

const mapDispatchToProps = { updatePopupData: setPopupData };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PopupNode));
