import * as React from 'react';

import PopupAdaptation from './PopupAdaptation';
import PopupNode from './PopupNode';
import PopupPipe from './PopupPipe';
import PopupWell from './PopupWell';
import { IPopupComponentProps } from './types';

const PopupComponent: React.FC<IPopupComponentProps> = ({ type }) => {
  if (type === 'node') return <PopupNode />;
  if (type === 'pipe') return <PopupPipe />;
  if (type === 'adaptation') return <PopupAdaptation />;
  return <PopupWell />;
};

export default PopupComponent;
