import React from 'react';

import classnames from 'classnames';

import { ReactComponent as CloseIcon } from '../../assets/images/settings/close.svg';
import { createWellfield } from '../../Settings/service';
import { WellfieldError } from '../../Settings/types';
import Button from '../Button';
import Input from '../Input';
import { IConfirmationWindowProps } from './types';

import './style.scss';
import '../../Settings/settings.scss';

interface IConfirmationWindowState {
  wellfieldName: string;
  wellfieldNameError: WellfieldError;
  wellfieldLink: string;
  wellfieldLinkError: WellfieldError;
  execution: boolean;
}

class DialogWindow extends React.Component<IConfirmationWindowProps> {
  state: IConfirmationWindowState = {
    wellfieldName: '',
    wellfieldLink: '',
    wellfieldLinkError: { error: false },
    wellfieldNameError: { error: false },
    execution: false,
  };

  onPrimaryButtonClick = async () => {
    const { getWellfieldOptionsList, closeWindow } = this.props;
    const { wellfieldName, wellfieldLink } = this.state;
    let isErrors = false;
    if (!wellfieldName) {
      isErrors = true;
      this.setState({ wellfieldNameError: { error: true } });
    }
    if (!wellfieldLink) {
      isErrors = true;
      this.setState({ wellfieldLinkError: { error: true } });
    }
    if (!isErrors) {
      this.setState({ execution: true });
      try {
        await createWellfield(wellfieldName, wellfieldLink);
        closeWindow();
        getWellfieldOptionsList();
      } catch (e) {
        //
      }
      this.setState({ execution: false });
    }
  };

  onSecondaryButtonClick = () => {
    const { closeWindow } = this.props;
    closeWindow();
  };

  onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ wellfieldName: e.target.value });
  };

  onChangeLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ wellfieldLink: e.target.value });
  };

  render() {
    const {
      wellfieldNameError,
      wellfieldName,
      wellfieldLink,
      wellfieldLinkError,
      execution,
    } = this.state;
    const { closeWindow } = this.props;
    const nameInputMode = wellfieldNameError.error ? 'error' : 'normal';
    const linkInputMode = wellfieldLinkError.error ? 'error' : 'normal';
    const addBtnMode = execution ? 'execution' : 'primary';

    return (
      <div className="dialog-window">
        <div className="dialog-window-container">
          <button
            type="button"
            className="dialog-window-close-button"
            onClick={closeWindow}
          >
            <CloseIcon />
          </button>
          <div className="dialog-window-content-container">
            <div className="dialog-window-title">
              Добавление месторождения / модели
            </div>
            <div className="settings-dropdown-label">Название</div>
            <div className="wellfield-input-container relative">
              <Input
                value={wellfieldName}
                mode={nameInputMode}
                onChange={this.onChangeName}
              />
              <div
                className={classnames(
                  'settings-tooltip wellfield-dialog-tooltip',
                  wellfieldNameError?.error && 'error',
                )}
              >
                <span className="settings-tooltip-text">Введите значение</span>
              </div>
            </div>
            <div className="settings-dropdown-label">
              Ссылка на проект в ЭРА:ИСКРА / редакторе
            </div>
            <div className="link-input-container relative">
              <Input
                value={wellfieldLink}
                mode={linkInputMode}
                onChange={this.onChangeLink}
              />
              <div
                className={classnames(
                  'settings-tooltip wellfield-dialog-tooltip',
                  wellfieldLinkError?.error && 'error',
                )}
              >
                <span className="settings-tooltip-text">Введите значение</span>
              </div>
            </div>
          </div>
          <div className="dialog-window-button-wrapper">
            <div className="primary-button-wrapper">
              <Button onClick={this.onPrimaryButtonClick} mode={addBtnMode}>
                {addBtnMode === 'execution' ? 'Идет добавление' : 'Добавить'}
              </Button>
            </div>
            <Button onClick={this.onSecondaryButtonClick} mode="secondary">
              Отмена
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default DialogWindow;
