import * as React from 'react';
import { connect } from 'react-redux';

import { selectGradientType, selectGradientValues } from 'Map/mapSlice';
import { StoreType } from 'store';

import { formatterForInt } from 'utils';
import { IGradientProps } from './types';

const Gradient: React.FC<IGradientProps> = ({
  gradientType,
  gradientValues,
}) => {
  if (gradientType === 'monochrome') return null;

  const { max, min } = gradientValues;

  return (
    <div className="gradient">
      <div className="relative">
        <p className="gradient__type">
          {gradientType === 'wor' ? 'WOR' : 'ΔP/ΔL'}
        </p>
      </div>
      <div className="gradient__container">
        <div className="gradient__line">
          <div className="gradient__dots" />
          <div className="gradient__dots" />
          <div className="gradient__dots" />
          <div className="gradient__dots" />
          <div className="gradient__dots" />
        </div>
        <div className="gradient__values">
          <p className="gradient__title">{formatterForInt.format(min)}</p>
          <p className="gradient__title">
            {formatterForInt.format((max - min) / 4)}
          </p>
          <p className="gradient__title">
            {formatterForInt.format((max - min) / 2)}
          </p>
          <p className="gradient__title">
            {formatterForInt.format(((max - min) * 3) / 4)}
          </p>
          <p className="gradient__title">{formatterForInt.format(max)}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: StoreType) => ({
  gradientType: selectGradientType(store),
  gradientValues: selectGradientValues(store),
});

export default connect(mapStateToProps)(Gradient);
