import * as React from 'react';

import classnames from 'classnames';

import { IButton } from './types';

import './style.scss';

const Button: React.FC<IButton> = ({
  className,
  children,
  mode = 'primary',
  onClick,
}) => (
  <button className={classnames('button', [mode, className])} onClick={onClick}>
    {children}
  </button>
);

export default Button;
