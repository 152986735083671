import React, { useState } from 'react';
import { ValueType } from 'react-select';

import classnames from 'classnames';

import Dropdown from '../components/Dropdown';
import Input from '../components/Input';
import { CenterType, OptionType } from '../Settings/types';
import { formatterForInt } from '../utils';
import stringFormatter from '../utils/stringFormatter';

import '../Settings/GatheringCentersSettings/gatheringCenterSettings.scss';

interface ISelectCenterRowProps {
  centersOptions: ValueType<OptionType, false>[];
  centerValue: ValueType<OptionType, false>;
  setCenterValue(value: ValueType<OptionType, false>): void;
  centerToAdd: CenterType;
  setCenterToAdd(value: CenterType);
}

// tslint:disable-next-line:max-func-body-length
const SelectGatheringCenterRow: React.FC<ISelectCenterRowProps> = ({
  centersOptions,
  centerValue,
  setCenterValue,
  centerToAdd,
  setCenterToAdd,
}) => {
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const pressureInputMode = centerToAdd.pressureError ? 'error' : 'normal';
  const pressureInputValue = centerToAdd.pressure ? centerToAdd.pressure : '';
  const onChangePressure = (e: React.ChangeEvent<HTMLInputElement>) => {
    const spaces =
      stringFormatter(e.target.value).length - e.target.value.length;
    if (spaces > 0) {
      if (e.target.selectionStart) {
        setCursorPosition(e.target.selectionStart + spaces);
      }
    } else setCursorPosition(e.target.selectionStart);
    const center: CenterType = { ...centerToAdd };
    center.pressure = e.target.value;
    center.pressureError = false;
    setCenterToAdd(center);
  };
  const onChangeCenterName = (e: ValueType<OptionType, false>) => {
    setCenterValue(e);
    const center: CenterType = { ...centerToAdd };
    center.name = (e as OptionType).label;
    center.nameError = false;
    setCenterToAdd(center);
  };
  const onBlurHandler = () => {
    const center: CenterType = { ...centerToAdd };
    if (centerToAdd.pressure) {
      const commaToDotValue = centerToAdd.pressure.replace(',', '.');
      const valueWithoutSpaces = commaToDotValue.replace(/\s/g, '');
      const newValue = parseFloat(valueWithoutSpaces);
      if (newValue) {
        center.pressure = formatterForInt.format(newValue).toString();
      } else {
        center.pressure = centerToAdd.pressure;
        center.pressureError = true;
        center.pressureErrorType = 'notNumber';
      }
    } else {
      center.pressureError = true;
      center.pressureErrorType = 'emptyValue';
    }
    setCenterToAdd(center);
  };

  return (
    <div className="gathering-center-row select-row calc-settings-select-row">
      <div className="gathering-center-column left">
        <span className="settings-dropdown-label">Центр сбора</span>
        <div className="settings-dropdown-container relative">
          <Dropdown
            options={centersOptions as OptionType[]}
            placeholder=""
            value={centerValue}
            onChange={onChangeCenterName}
            error={centerToAdd.nameError}
          />
          <div
            className={classnames(
              'settings-tooltip',
              centerToAdd.nameError ? 'error' : '',
            )}
          >
            <span className="settings-tooltip-text">
              {centerToAdd.nameErrorType === 'noCentersAdded'
                ? 'Для расчета необходим хотя бы 1 ЦС'
                : 'Выберите ЦС, чтобы добавить его в расчет'}
            </span>
          </div>
        </div>
      </div>
      <div className="dash-column left calc-settings-dash" />
      <div className="pressure-column left">
        <span className="settings-dropdown-label">Давление, атм</span>
        <div className="settings-input-container relative">
          <Input
            value={stringFormatter(pressureInputValue)}
            onChange={onChangePressure}
            mode={pressureInputMode}
            onBlur={onBlurHandler}
            cursorPosition={cursorPosition}
          />
          <div
            className={classnames(
              'settings-tooltip gathering-center-pressure-tooltip',
              centerToAdd.pressureErrorType === 'emptyValue'
                ? 'empty-value'
                : 'not-number',
              centerToAdd.pressureError ? 'error' : '',
            )}
          >
            <span className="settings-tooltip-text">
              {centerToAdd.pressureErrorType === 'emptyValue'
                ? 'Укажите давление на ЦС'
                : 'Введите числовое значение'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectGatheringCenterRow;
