import React from 'react';
import { ValueType } from 'react-select';

import DetailedTaskSettings from './DetailedTaskSettings/DetailedTaskSettings';
import GatheringCenterSettings from './GatheringCentersSettings/GatheringCenterSettings';
import TaskSettings from './TaskSettings/TaskSettings';
import {
  CenterType,
  dateTimeValueError,
  DropzoneError,
  OptionType,
  ParameterType,
  UploadedFileError,
  UploadedFileType,
  WellfieldError,
  WellfieldOption,
  WellfieldValue,
} from './types';
import WellfieldSettings from './WellfieldSettings/WellfieldSettings';

import './WellfieldSettings/wellfieldSettings.scss';

interface ISettingsFrameProps {
  index: number;
  wellfieldValue?: WellfieldValue;
  setWellfieldValue(value: ValueType<OptionType, false>): void;
  wellfieldValueError?: WellfieldError;
  setWellfieldValueError?(value: WellfieldError): void;
  uploadedFiles: UploadedFileType[];
  setUploadedFiles(files: UploadedFileType[]): void;
  dropzoneError: DropzoneError;
  setDropzoneError(error: DropzoneError): void;
  centerToAdd: CenterType;
  setCenterToAdd(value: CenterType);
  centerValue: ValueType<OptionType, false>;
  setCenterValue(value: ValueType<OptionType, false>): void;
  centers: CenterType[];
  setCenters(centers: CenterType[]): void;
  initOptions: OptionType[];
  centersOptions: ValueType<OptionType, false>[];
  setCentersOptions(options: ValueType<OptionType, false>[]);
  parameters: ParameterType[];
  setParameters(value: ParameterType[]);
  setAdaptationMethod(value: string): void;
  adaptationMethod: string;
  forceRerender?: boolean;
  setForceRerender?(value: boolean);
  wellfieldOptions: WellfieldOption[];
  dateTimeOptions: OptionType[];
  setDateTimeValue(val: ValueType<OptionType, false>): void;
  dateTimeValue: ValueType<OptionType, false>;
  dateTimeValueError: dateTimeValueError;
  showWellfieldDialog(): void;
}

const SettingsFrame: React.FC<ISettingsFrameProps> = ({
  index,
  wellfieldValue,
  setWellfieldValue,
  wellfieldValueError,
  setWellfieldValueError,
  uploadedFiles,
  setUploadedFiles,
  dropzoneError,
  setDropzoneError,
  centerToAdd,
  setCenterToAdd,
  centerValue,
  setCenterValue,
  centers,
  setCenters,
  initOptions,
  centersOptions,
  setCentersOptions,
  parameters,
  setParameters,
  setAdaptationMethod,
  adaptationMethod,
  wellfieldOptions,
  dateTimeOptions,
  dateTimeValue,
  setDateTimeValue,
  dateTimeValueError,
  showWellfieldDialog,
}) => {
  const filesErrors: UploadedFileError[] = [];
  for (const file of uploadedFiles) {
    if (file.error.error) filesErrors.push(file.error);
  }
  switch (index) {
    case 0:
      return (
        <WellfieldSettings
          wellfieldValue={wellfieldValue}
          setWellfieldValue={setWellfieldValue}
          wellfieldValueError={wellfieldValueError}
          setWellfieldValueError={setWellfieldValueError}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          dropzoneError={dropzoneError}
          setDropzoneError={setDropzoneError}
          wellfieldOptions={wellfieldOptions}
          uploadedFilesErrors={filesErrors}
          showWellfieldDialog={showWellfieldDialog}
        />
      );
    case 1:
      return (
        <GatheringCenterSettings
          centerToAdd={centerToAdd}
          setCenterToAdd={setCenterToAdd}
          centerValue={centerValue}
          setCenterValue={setCenterValue}
          centers={centers}
          setCenters={setCenters}
          initOptions={initOptions}
          centersOptions={centersOptions}
          setCentersOptions={setCentersOptions}
        />
      );
    case 2:
      return (
        <TaskSettings parameters={parameters} setParameters={setParameters} />
      );
    case 3:
      return (
        <DetailedTaskSettings
          setAdaptationMethod={setAdaptationMethod}
          dateTimeOptions={dateTimeOptions}
          dateTimeValue={dateTimeValue}
          setDateTimeValue={setDateTimeValue}
          dateTimeValueError={dateTimeValueError}
          adaptationMethod={adaptationMethod}
        />
      );
    default:
      return <></>;
  }
};

export default SettingsFrame;
