import * as React from 'react';
import { Feature, Layer } from 'react-mapbox-gl';
import { connect } from 'react-redux';

import { PipelineNetworkNode } from 'generatedApiTypes';
import { selectShowLables, selectWellfieldData } from 'Map/mapSlice';
import { selectTaskSolution } from 'Network/networkSlice';
import { StoreType } from 'store';

import { IIconsComponentProps } from './types';
import {
  filterByNodeType,
  handleOffset,
  layoutBaseProps,
  paint,
} from './utils';

class IconsComponent extends React.Component<IIconsComponentProps> {
  onMouseEnter = (e: any) => {
    const { layerKey } = this.props;
    const { map, feature } = e;
    const { properties } = feature;
    const coordinates = JSON.parse(properties.coords);
    const sourceId = `${layerKey}_hover`;
    const source = map.getSource(sourceId);
    const mapContainer = map.getCanvas();
    const data = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties,
          geometry: {
            type: 'Point',
            coordinates,
          },
        },
      ],
    };

    source.setData(data);

    mapContainer.style.cursor = 'pointer';

    map.on('click', sourceId, this.onClickHandler);
    map.on('mouseleave', sourceId, () => {
      source.setData({
        type: 'FeatureCollection',
        features: [],
      });
      mapContainer.style.cursor = 'grab';
      map.off('click', sourceId, this.onClickHandler);
    });
  };

  onClickHandler = (e: any) => {
    const { propertiesType } = this.props;
    const feature = e.features[0];
    const { coordinates: popupCoords } = feature.geometry;
    const { node } = feature.properties;
    const { state, ...props } = JSON.parse(node);

    this.props.handlePopup({
      popupCoords,
      showPopup: true,
      popup: propertiesType,
      activeValue: { ...state, ...props },
    });
  };

  render() {
    const {
      nodeType,
      layerKey,
      iconImage,
      iconImageHover,
      taskSolution,
      wellfieldData,
      propertiesType,
      layoutProps = {},
      showLabels,
      showJunction,
    } = this.props;
    let nodes: PipelineNetworkNode[] = [];

    if (wellfieldData) nodes = wellfieldData?.nodes;

    if (taskSolution)
      nodes = taskSolution?.nodes || taskSolution?.wellfield_graph?.nodes;

    const icons = nodes?.filter(filterByNodeType(nodeType));

    if (!icons?.length) return null;

    const layout = {
      'icon-image': iconImage,
      ...layoutBaseProps,
      ...layoutProps,
    };

    if (showLabels) {
      const offset = handleOffset(nodeType);

      if (
        (nodeType === 'JUNCTION' && showJunction) ||
        ['SINK', 'SOURCE'].includes(nodeType)
      ) {
        layout['text-field'] = '{name}';
        layout['text-offset'] = offset;
      }
    }

    return (
      <>
        <Layer
          id={`${layerKey}_hover`}
          type="symbol"
          layout={{
            'icon-image': iconImageHover,
            'text-allow-overlap': true,
            'icon-allow-overlap': true,
            ...layoutProps,
          }}
          geoJSONSourceOptions={{ generateId: true }}
        />
        <Layer
          id={`${layerKey}`}
          type="symbol"
          layout={layout}
          paint={paint}
          geoJSONSourceOptions={{ generateId: true }}
        >
          {icons?.map((source, index) => {
            const { coords } = source;
            if (!coords) return null;

            const properties = {
              coords,
              type: propertiesType,
              node: source,
              name: source.name,
              node_type: source.node_type,
            };

            return (
              <Feature
                key={index}
                coordinates={coords}
                properties={properties}
                onMouseEnter={this.onMouseEnter}
                onClick={this.onClickHandler}
              />
            );
          })}
        </Layer>
      </>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
  wellfieldData: selectWellfieldData(store),
  showLabels: selectShowLables(store),
});

export default connect(mapStateToProps)(IconsComponent);
