import React from 'react';

import { ReactComponent as CloseEyeIcon } from '../../assets/images/settings/eye-close-line.svg';
import { ReactComponent as EyeIcon } from '../../assets/images/settings/eye-fill.svg';

import '../settings.scss';

interface IOverviewButtonProps {
  toggleOverviewPanel(): void;
  panelIsActive: boolean;
}

class OverviewButton extends React.Component<IOverviewButtonProps> {
  onButtonClick = () => {
    const { toggleOverviewPanel } = this.props;
    toggleOverviewPanel();
  };

  render() {
    const { panelIsActive } = this.props;
    return (
      <div className="overview-button-container">
        <div className="overview-button" onClick={this.onButtonClick}>
          <div className="overview-button-icon">
            {panelIsActive ? <EyeIcon /> : <CloseEyeIcon />}
          </div>
          Сведения о сети
        </div>
      </div>
    );
  }
}

export default OverviewButton;
