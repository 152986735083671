import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectPopupData, setPopupData } from 'Map/mapSlice';
import { StoreType } from 'store';

import { formatter } from 'utils';
import PopupTopRow from '../PopupTopRow';
import TopRowLeftCol from './TopRowLeftCol';
import TopRowRightCol from './TopRowRightCol';
import { IPopupPipeProps } from './types';

const PopupPipe: React.FC<IPopupPipeProps> = ({
  history,
  popupData,
  updatePopupData,
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    ref.current?.focus();
  }, []);

  const hidePopup = () => updatePopupData(null);

  const {
    length_m: length,
    diameter_mm: diameter,
    wall_thickness_mm: thickness,
    aspo_mm: aspo,
    theta_grad: theta,
    dp_dl_atm_km: dPdLAtmKm,
    pressure_in_atm: pressureIn,
    pressure_out_atm: pressureOut,
    oil_flow_rate_ton_day: oilFlow,
    fluid_flow_rate_ton_day: fluidFlow,
    gas_flow_rate_m3_day: gasFlow,
    start_node_name: startNode,
    end_node_name: endNode,
  } = popupData;

  const {
    location: { pathname },
  } = history;

  const isSettings = pathname.includes('settings');

  return (
    <div tabIndex={-1} onBlur={hidePopup} ref={ref}>
      <PopupTopRow firstTitle={startNode} secondTitle={endNode} />
      <table>
        <tbody>
          <tr className="popup-table-row">
            <TopRowLeftCol
              length={length}
              diameter={diameter}
              thickness={thickness}
            />
            {!isSettings && <TopRowRightCol aspo={aspo} theta={theta} />}
          </tr>
          {!isSettings && (
            <tr className="popup-table-row">
              <td className="popup-table-col">
                <div className="popup-row__sub-row">
                  <p className="popup-val-dem">
                    <span className="relative sub-index-container">
                      P<span className="sub-index">0</span>
                    </span>{' '}
                    , атм
                  </p>
                  <p className="popup-title">{formatter.format(pressureIn)}</p>
                </div>
                <div className="popup-row__sub-row">
                  <p className="popup-val-dem">
                    <span className="relative sub-index-container">
                      P<span className="sub-index">1</span>
                    </span>{' '}
                    , атм
                  </p>
                  <p className="popup-title">{formatter.format(pressureOut)}</p>
                </div>
                <div className="popup-row__sub-row">
                  <p className="popup-val-dem">ΔP/ΔL, атм/км</p>
                  <p className="popup-title">{formatter.format(dPdLAtmKm)}</p>
                </div>
              </td>
              <td>
                <div className="popup-row__sub-row">
                  <p className="popup-val-dem">
                    Qж,{' '}
                    <span className="relative">
                      м<span className="index">3</span>
                    </span>{' '}
                    /сут
                  </p>
                  <p className="popup-title">{formatter.format(fluidFlow)}</p>
                </div>
                <div className="popup-row__sub-row">
                  <p className="popup-val-dem">Qн, т/сут</p>
                  <p className="popup-title">{formatter.format(oilFlow)}</p>
                </div>
                <div className="popup-row__sub-row">
                  <p className="popup-val-dem">
                    Qг, тыс.{' '}
                    <span className="relative">
                      м<span className="index">3</span>
                    </span>{' '}
                    /сут
                  </p>
                  <p className="popup-title">{formatter.format(gasFlow)}</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store: StoreType) => ({
  popupData: selectPopupData(store),
});

const mapDispatchToProps = {
  updatePopupData: setPopupData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PopupPipe));
