import React from 'react';

import { Skeleton } from '@material-ui/lab';

import { formatter } from '../../utils';
import { handleTdIndex } from './utils';

const SkeletonComponent = (value: string | number, tdIndex: number) => {
  if (value !== undefined) {
    if (typeof value === 'string') return value;
    return formatter.format(value);
  }
  return (
    <Skeleton
      variant="rect"
      classes={{
        root: `skeleton-root ${handleTdIndex(tdIndex)}`,
      }}
    />
  );
};

export default SkeletonComponent;
