import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  selectPopupData,
  selectWellfieldData,
  setPopupData,
} from 'Map/mapSlice';
import { selectTaskSolution } from 'Network/networkSlice';
import { StoreType } from 'store';

import TableRow from './TableRow';
import { PopupWellPropsType } from './types';

const PopupWell: React.FC<PopupWellPropsType> = ({
  popupData,
  updatePopupData,
  taskSolution,
  history,
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    ref.current?.focus();
  }, []);

  const hidePopup = () => updatePopupData(null);

  const { name, uid } = popupData;
  const wellsById = taskSolution?.wellsById || {};
  const wells = wellsById[uid] || [];
  const {
    location: { pathname },
  } = history;

  const isSettings = pathname.includes('settings');

  return (
    <div tabIndex={-1} onBlur={hidePopup} ref={ref}>
      <div className="popup-top-row">
        <div className="popup-title">{name}</div>
      </div>
      {!isSettings && (
        <table>
          <tbody>
            {wells.map((el, key) => {
              const {
                frequency_hz: frequency,
                fluid_flow_rate_ton_day: fluidFlow,
                gas_flow_rate_m3_day: gasFlow,
                oil_flow_rate_ton_day: oilFlow,
                pressure_atm: pressure,
              } = el;

              return (
                <TableRow
                  key={key}
                  title={`Скважина ${el.name}`}
                  data={{
                    pressure,
                    frequency,
                    fluidFlow,
                    oilFlow,
                    gasFlow,
                  }}
                />
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

const mapStateToProps = (store: StoreType) => ({
  popupData: selectPopupData(store),
  taskSolution: selectTaskSolution(store),
  wellfieldData: selectWellfieldData(store),
});

const mapDispatchToProps = { updatePopupData: setPopupData };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PopupWell));
