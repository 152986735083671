import * as React from 'react';

import { ReactComponent as Icon } from 'assets/images/moveToSelection.svg';

import { ISetCenterButtonProps } from './types';

const SetCenterButton: React.FC<ISetCenterButtonProps> = ({ onClick }) => (
  <button
    className="set-center-button custom-zoom-control__button"
    onClick={onClick}
  >
    <Icon />
  </button>
);

export default SetCenterButton;
