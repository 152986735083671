import { api } from 'api';

import { GetCenter } from './types';

export const getCenter: GetCenter = (wellfieldUid: string) =>
  api.get(
    'https://api.pipeline-network-optimization.numdes.com/api/wellfield_center',
    {
      params: {
        wellfield_uid: wellfieldUid,
      },
    },
  );
