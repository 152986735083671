export const handleStorage = {
  setToken: token => {
    return localStorage.setItem('accessToken', token);
  },
  getToken: () => {
    return localStorage.getItem('accessToken');
  },
  removeToken: () => {
    localStorage.removeItem('accessToken');
  },
};
