import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { ReactComponent as GradientIcon } from 'assets/images/gradient.svg';
import SettingsRadioButton from 'CalcSettings/SettingsRadioButton';
import classnames from 'classnames';
import { selectGradientType, setGradientType } from 'Map/mapSlice';
import { GradientType } from 'Map/types';
import { StoreType } from 'store';

import { IShowGradientProps } from './types';
import { radioButtons } from './utils';

const ShowGradient: React.FC<IShowGradientProps> = ({
  updateGradientType,
  gradientType,
  location,
}) => {
  const [show, setShow] = React.useState<boolean>(false);
  const onClickHandler = () => {
    setShow(!show);
  };

  const onChangeHandler = (value: GradientType) => {
    updateGradientType(value);
    setShow(false);
  };

  if (location.pathname.includes('settings')) return null;

  return (
    <div className="show-gradient-container">
      <button
        onClick={onClickHandler}
        className={classnames('set-center-button', { active: show })}
      >
        <GradientIcon />
      </button>
      {show && (
        <div className="show-gradient">
          <div className="show-gradient__triangle" />
          <p className="show-gradient__title">Цвет сегментов</p>
          <div>
            {radioButtons.map((el, key) => (
              <SettingsRadioButton
                key={key}
                id={el.id}
                value={el.value}
                label={el.label}
                name={el.name}
                checked={gradientType === el.gradientType}
                setValue={onChangeHandler}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store: StoreType) => ({
  gradientType: selectGradientType(store),
});

const mapDispatchToProps = { updateGradientType: setGradientType };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ShowGradient));
