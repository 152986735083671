import junctionIcon from 'assets/images/junction.svg';
import junctionHover from 'assets/images/junction-hover.svg';
import sinkIcon from 'assets/images/sink.svg';
import sinkHover from 'assets/images/sink-hover.svg';
import sourceIcon from 'assets/images/source.svg';
import sourceHover from 'assets/images/source-hover.svg';
import { NodeType } from 'generatedApiTypes';

import { FilterByNodeType, IIconsComponentProps } from './types';

export const imageNames = {
  'junction-icon': {
    src: junctionIcon,
    width: 10,
    height: 10,
  },
  'junction-icon-hover': {
    src: junctionHover,
    width: 10,
    height: 10,
  },
  'sink-icon': {
    src: sinkIcon,
    width: 34,
    height: 36,
  },
  'sink-icon-hover': {
    src: sinkHover,
    width: 50,
    height: 52,
  },
  'wells-icon': {
    src: sourceIcon,
    width: 30,
    height: 32,
  },
  'wells-hover': {
    src: sourceHover,
    width: 46,
    height: 48,
  },
};

export const icons: Omit<
  IIconsComponentProps,
  | 'handlePopup'
  | 'taskSolution'
  | 'wellfieldData'
  | 'map'
  | 'showLabels'
  | 'showJunction'
>[] = [
  {
    layerKey: 'wells',
    nodeType: 'SOURCE',
    iconImage: 'wells-icon',
    iconImageHover: 'wells-hover',
    propertiesType: 'wellfield',
    layoutProps: {
      'icon-offset': [0, -5],
    },
  },
  {
    layerKey: 'junctions',
    nodeType: 'JUNCTION',
    iconImage: 'junction-icon',
    iconImageHover: 'junction-icon-hover',
    propertiesType: 'node',
  },
  {
    layerKey: 'sinks',
    nodeType: 'SINK',
    iconImage: 'sink-icon',
    iconImageHover: 'sink-icon-hover',
    propertiesType: 'node',
    layoutProps: {
      'icon-offset': [0, -6],
    },
  },
];

const pixelRatio = 3;

export const addImagesOnMap = (map: any) => (key: string) => {
  const { src, width, height } = imageNames[key];
  const image = new Image(width * pixelRatio, height * pixelRatio);
  image.onload = () => map.addImage(key, image, { pixelRatio });
  image.src = src;
};

export const filterByNodeType: FilterByNodeType = type => ({ node_type }) =>
  node_type === type;

export const handleOffset = (nodeType: keyof typeof NodeType) => {
  if (nodeType === 'JUNCTION') return [0.7, -0.1];
  if (nodeType === 'SINK') return [0.8, -0.7];
  if (nodeType === 'SOURCE') return [0.8, -0.8];
  return [0, 0];
};

export const layoutBaseProps = {
  'text-allow-overlap': true,
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
  'text-ignore-placement': true,
  'text-size': 14,
  'text-anchor': 'left',
  'text-font': ['Futura PT Web Medium Regular', 'Open Sans Regular'],
};

export const paint = {
  'text-color': '#5877A0',
  'text-halo-color': '#FFFFFF',
  'text-halo-width': 1,
};
