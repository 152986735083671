import { createSelector, createSlice } from '@reduxjs/toolkit';
import { StoreType } from 'store';

import reducers from './networkReducers';
import { NetworkSliceStateType } from './types';

const initialState: NetworkSliceStateType = {};

export const networkSlice = createSlice({
  reducers,
  initialState,
  name: 'network',
});

export const { setTaskSolution } = networkSlice.actions;

const selectSelf = (store: StoreType) => store;

export const selectTaskSolution = createSelector(
  selectSelf,
  store => store.network.taskSolution,
);

export const selectTask = createSelector(
  selectTaskSolution,
  taskSolution => taskSolution?.task,
);

export type SelectTaskSolution = ReturnType<typeof selectTaskSolution>;

export type SelectTask = ReturnType<typeof selectTask>;

export default networkSlice.reducer;
