import * as React from 'react';

class LeftColBottomRow extends React.Component {
  static leftColRole: string;

  render() {
    return (
      <div className="left-col__bottom">
        {this.props.children}
        <div className="left-col__bottom-shadow" />
      </div>
    );
  }
}

LeftColBottomRow.leftColRole = 'BottomRow';

export default LeftColBottomRow;
