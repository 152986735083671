import * as React from 'react';

import { Skeleton } from '@material-ui/lab';

import { formatter } from '../../utils';

const SkeletonComponent = (value?: string | number) => {
  if (value) {
    if (typeof value === 'string') {
      return value;
    }
    return formatter.format(value);
  }

  return (
    <Skeleton
      variant="rect"
      classes={{
        root: 'skeleton-root dashboard-skeleton',
      }}
    />
  );
};

export default SkeletonComponent;
