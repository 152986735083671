import React from 'react';

import { DropzoneErrorType, UploadedFileErrorType } from '../types';
import ErrorRow from './ErrorRow';
import { IErrorsListProps } from './types';

class ErrorsList extends React.Component<IErrorsListProps> {
  getMessageByErrorType = (
    errorType:
      | keyof typeof DropzoneErrorType
      | keyof typeof UploadedFileErrorType
      | undefined,
    fileName: string,
  ) => {
    switch (errorType) {
      case 'noTechModeFile':
        return 'Отсутствует файл «TexРежим.xls»';
      case 'noWellsFile':
        return 'Отсутствует файл «Скважины.xlsx»';
      case 'noOISPipeFile':
        return 'Отсутствует файл «Фактические измерения из OIS Pipe.xlsx»';
      case 'wrongFile':
        return `Файл «${fileName}» имеет некорректную структуру`;
      case 'wrongName':
        return `Неправильное название файла «${fileName}»`;
      case 'notCorrespondingFile':
        return 'Файлы не соотносятся между собой (относятся к разным месторождениям)';
      default:
        return '';
    }
  };

  getDescriptionByErrorType = (
    errorType:
      | keyof typeof DropzoneErrorType
      | keyof typeof UploadedFileErrorType
      | undefined,
  ) => {
    switch (errorType) {
      case 'noTechModeFile':
        return '';
      case 'noWellsFile':
        return '';
      case 'wrongFile':
        return '';
      case 'wrongName':
        return 'Название может быть «ТехРежим.xls», «Скважины.xlsx» или «Фактические измерения из OIS Pipe.xlsx». Файлы с другими названиями, но при этом с правильной структурой не будут загружены.';
      default:
        return '';
    }
  };

  render() {
    const { uploadedFiles, dropzoneError } = this.props;
    return (
      <div className="wellfield-settings-errors-list-container">
        {dropzoneError?.error &&
          dropzoneError?.errorsList.map((item, index) => {
            if (item !== 'fileError') {
              return (
                <ErrorRow
                  key={index}
                  errorMessage={this.getMessageByErrorType(item, '')}
                  errorDescription={this.getDescriptionByErrorType(item)}
                />
              );
            }
          })}
        {uploadedFiles
          ?.filter(file => file.error.errorType !== 'notCorrespondingFile')
          .map((item, index) => {
            if (item?.error.error) {
              return (
                <ErrorRow
                  key={index}
                  errorMessage={this.getMessageByErrorType(
                    item.error.errorType,
                    item.name,
                  )}
                  errorDescription={this.getDescriptionByErrorType(
                    item.error.errorType,
                  )}
                />
              );
            }
          })}
        {uploadedFiles
          ?.filter(
            (file, index, self) =>
              index ===
              self.findIndex(t => t.error.errorType === 'notCorrespondingFile'),
          )
          .filter(item => item?.error.error)
          .map((item, index) => (
            <ErrorRow
              key={index}
              errorMessage={this.getMessageByErrorType(
                item.error.errorType,
                item.name,
              )}
              errorDescription={this.getDescriptionByErrorType(
                item.error.errorType,
              )}
            />
          ))}
      </div>
    );
  }
}

export default ErrorsList;
