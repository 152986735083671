import * as React from 'react';

import CustomZoomButton from './CustomZoomButton';
import SetCenterButton from './SetCenterButton';
import ShowGradient from './ShowGradient';
import ShowLabels from './ShowLabels';
import { ICustomZoomControlProps, ZoomButtonType } from './types';

const CustomZoomControl: React.FC<ICustomZoomControlProps> = ({
  mapRef,
  mapCenter,
}) => {
  const zoomHandler = (buttonType: ZoomButtonType) => {
    const { map } = mapRef.current.state;
    const diff = buttonType === 'zoom-in' ? 0.5 : -0.5;
    const zoom = map.getZoom() + diff;
    mapRef.current.state.map.zoomTo(zoom);
  };
  const setCenter = () => {
    const { map } = mapRef.current.state;
    const zoom = map.getZoom();
    map.flyTo({ zoom, center: mapCenter });
  };

  return (
    <div className="custom-zoom-control">
      <div className="relative custom-zoom-control__sub-container">
        <div className="custom-zoom-control__line" />
        <CustomZoomButton zoomType="zoom-in" zoomHandler={zoomHandler} />
        <CustomZoomButton zoomType="zoom-out" zoomHandler={zoomHandler} />
      </div>
      <SetCenterButton onClick={setCenter} />
      <ShowGradient />
      <ShowLabels />
    </div>
  );
};

export default CustomZoomControl;
