import { createSelector, createSlice } from '@reduxjs/toolkit';
import { StoreType } from 'store';

import reducers from './mapReducers';
import { MapSliceStateType } from './types';

const initialState: MapSliceStateType = {
  popup: null,
  wellfieldData: null,
  showLables: false,
  gradientType: 'monochrome',
  gradientValues: {
    min: 0,
    max: 0,
  },
};

export const networkSlice = createSlice({
  reducers,
  initialState,
  name: 'map',
});

export const {
  setPopupData,
  setShowLables,
  setWellfieldData,
  setGradientType,
  setGradientValues,
} = networkSlice.actions;

const selectSelf = (store: StoreType) => store;

export const selectPopupData = createSelector(
  selectSelf,
  store => store.map.popup,
);

export const selectWellfieldData = createSelector(
  selectSelf,
  store => store.map.wellfieldData,
);

export const selectGradientType = createSelector(
  selectSelf,
  store => store.map.gradientType,
);

export const selectGradientValues = createSelector(
  selectSelf,
  store => store.map.gradientValues,
);

export const selectShowLables = createSelector(
  selectSelf,
  store => store.map.showLables,
);

export type SelectWellfieldData = ReturnType<typeof selectWellfieldData>;

export type SelectPopupData = ReturnType<typeof selectPopupData>;

export type SelectGradientType = ReturnType<typeof selectGradientType>;

export type SelectGradientValues = ReturnType<typeof selectGradientValues>;

export type SelectShowLables = ReturnType<typeof selectShowLables>;

export type SetGradientTypeAction = typeof setGradientType;

export type SetGradientValuesTypeAction = typeof setGradientValues;

export type SetShowLablesTypeAction = typeof setShowLables;

export default networkSlice.reducer;
