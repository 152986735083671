import * as React from 'react';

import { ITableHeadProps, ITheadComponent } from './types';

const TableHead: React.FC<ITableHeadProps> = ({ headKeys, tableHead }) => {
  return (
    <thead>
      <tr className="table-head__row">
        {headKeys.map((key: string, index: number) => {
          let title: string | React.ReactNode = tableHead[key];
          if (typeof tableHead[key] !== 'string') {
            title = (tableHead[key] as ITheadComponent).component;
          }
          return (
            <th className="table-head__cell" key={index}>
              {title}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
