import React from 'react';
import { ValueType } from 'react-select';

import classnames from 'classnames';

import Dropdown from '../../components/Dropdown';
import { CenterType, OptionType } from '../types';

import './gatheringCenterSettings.scss';

interface ISelectCenterRowProps {
  centers: CenterType[];
  setCenters(centers: CenterType[]): void;
  centersOptions: ValueType<OptionType, false>[];
  setCentersOptions(options: ValueType<OptionType, false>[]);
  centerValue: ValueType<OptionType, false>;
  setCenterValue(value: ValueType<OptionType, false>): void;
  centerToAdd: CenterType;
  setCenterToAdd(value: CenterType);
  forceRerender?: boolean;
  setForceRerender?(value: boolean);
}

const SelectGatheringCenterRow: React.FC<ISelectCenterRowProps> = ({
  centers,
  setCenters,
  centersOptions,
  setCentersOptions,
  centerValue,
  setCenterValue,
  centerToAdd,
  setCenterToAdd,
}) => {
  const onChangeCenterName = (e: ValueType<OptionType, false>) => {
    setCenterValue(e);
    const center: CenterType = { ...centerToAdd };
    center.name = (e as OptionType).label;
    center.nameError = false;
    setCenterToAdd(center);
  };
  const addCenter = () => {
    const center: CenterType = { ...centerToAdd };
    if ((centerValue as OptionType)?.label) {
      const newCenter: CenterType = {
        pressure: centerToAdd.pressure,
        name: (centerValue as OptionType).label,
        uid: (centerValue as OptionType).value,
      };
      setCenters([newCenter, ...centers]);
      const currentOptions = [...centersOptions];
      const ix = currentOptions.findIndex(obj => {
        return (obj as OptionType).label === (centerValue as OptionType).label;
      });
      if (ix > -1) {
        currentOptions.splice(ix, 1);
      }
      setCentersOptions(currentOptions);
      setCenterValue(null);
      center.name = '';
      center.uid = '';
      center.pressure = undefined;
      center.nameError = false;
    } else if (!(centerValue as OptionType)?.label) {
      center.nameError = true;
      center.nameErrorType = 'notSelected';
    }
    setCenterToAdd(center);
  };
  return (
    <div className="gathering-center-row select-row">
      <div className="gathering-center-column left">
        <span className="settings-dropdown-label">Центр сбора</span>
        <div className="settings-dropdown-container gathering-centers-dropdown relative">
          <Dropdown
            options={centersOptions as OptionType[]}
            placeholder=""
            value={centerValue}
            onChange={onChangeCenterName}
            error={centerToAdd.nameError}
          />
          <div
            className={classnames(
              'settings-tooltip',
              centerToAdd.nameError ? 'error' : '',
            )}
          >
            <span className="settings-tooltip-text">
              {centerToAdd.nameErrorType === 'noCentersAdded'
                ? 'Для расчета необходим хотя бы 1 ЦС'
                : 'Выберите ЦС, чтобы добавить его в расчет'}
            </span>
          </div>
        </div>
      </div>
      <button className="gathering-center-row-btn add-btn" onClick={addCenter}>
        Добавить
      </button>
    </div>
  );
};

export default SelectGatheringCenterRow;
