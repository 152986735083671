import { PipelineNetworkNode, PipelineNetworkSegment } from 'generatedApiTypes';
import { PopupType } from 'Map/PopupContainer/types';

type SetActiveType = (type: PopupType, el: any, activeValue?: any) => boolean;

export const setActive: SetActiveType = (type, el, activeValue) => {
  if (type === 'wellfield' && activeValue) {
    const nodeUid = activeValue?.uid ? activeValue?.uid : activeValue?.node_uid;
    const uid = el?.node_uid;

    if (nodeUid && uid) {
      return nodeUid === uid;
    }
  }

  if (type === 'node' && activeValue) {
    const nodeUid = activeValue?.node_uid
      ? activeValue?.node_uid
      : activeValue?.uid;
    const uid = el?.uid;

    if (nodeUid && uid) {
      return nodeUid === uid;
    }
  }

  if (type === 'pipe' && activeValue) {
    const elStartNodeUid = el?.start_node_uid;
    const elEndNodeUid = el?.end_node_uid;
    const activeValueStartNodeUid = activeValue?.start_node_uid;
    const activeValueEndNodeUid = activeValue?.end_node_uid;

    if (elStartNodeUid && activeValueStartNodeUid) {
      return (
        elStartNodeUid === activeValueStartNodeUid &&
        elEndNodeUid === activeValueEndNodeUid
      );
    }
  }

  return false;
};

export const handleTdIndex = (index: number) => {
  if (index === 0) {
    return 'table-skeleton-72';
  }
  if (index <= 3) {
    return 'table-skeleton-24';
  }
  if (index <= 6) {
    return 'table-skeleton-56';
  }
  return '';
};

export const calculateMidPoint = (...args: number[][]): number[] =>
  args
    .reduce((acc: number[] = [], cur) => {
      if (!acc[0] && !acc[1]) {
        acc[0] = cur[0];
        acc[1] = cur[1];
      } else {
        acc[0] += cur[0];
        acc[1] += cur[1];
      }
      return acc;
    }, [])
    .map((el: number) => el / args.length);

export const composeNodesById = (acc = {}, el: PipelineNetworkNode) => {
  acc[el.uid!] = el;
  return acc;
};

export const filterById = (el: PipelineNetworkSegment) => (
  item: PipelineNetworkSegment,
) => item.uid === el.uid;

export const mergeNodesStateWithProps = ({
  state,
  ...rest
}: PipelineNetworkNode) => ({
  ...rest,
  ...state,
});
