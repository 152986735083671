import * as React from 'react';
import { Feature, Layer } from 'react-mapbox-gl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  CalibrationSolution,
  PipelineNetworkSegment,
  PipelineNetworkSegmentCalibrationResult,
} from 'generatedApiTypes';
import { setGradientValues, setPopupData } from 'Map/mapSlice';
import { selectTaskSolution } from 'Network/networkSlice';
import { StoreType } from 'store';

import { selectGradientType, selectWellfieldData } from './mapSlice';
import { HandlePopupType, IPipesLayerProps, IPipesLayerState } from './types';
import { firstColor, middleColor, secondColor } from './utils/gradientColors';
import handleCursor from './utils/handleCursor';
import pickHex3 from './utils/pickHex3';

const layout = { 'line-join': 'round', 'line-cap': 'round' };

class PipesLayer extends React.Component<IPipesLayerProps> {
  state: IPipesLayerState = {
    minAndMax: {
      min: 0,
      max: 0,
    },
    difference: 0,
  };

  componentDidUpdate(prevProps: IPipesLayerProps) {
    const { gradientType } = this.props;

    if (prevProps.gradientType !== gradientType) {
      const { taskSolution, wellfieldData } = this.props;
      let pipelines = [] as PipelineNetworkSegment[];
      if (wellfieldData) {
        pipelines = wellfieldData.pipelines;
      }
      if (taskSolution) {
        pipelines =
          taskSolution?.pipelines || taskSolution?.wellfield_graph?.pipelines;
      }

      if (['wor', 'dpdl'].includes(gradientType)) {
        const key = gradientType === 'dpdl' ? 'dp_dl_atm_km' : 'wor';
        const minAndMax = pipelines.reduce((acc: any = {}, cur) => {
          const value = cur[key];
          if (!acc.min) acc.min = value;
          if (!acc.max) acc.max = value;

          if (value) {
            if (value > acc.max) {
              acc.max = value;
            }
            if (value < acc.min) {
              acc.min = value;
            }
          }

          return acc;
        }, {});

        const difference = minAndMax?.max - minAndMax?.min;

        this.setState({ minAndMax, difference }, () => {
          this.props.setGradientValues(minAndMax);
        });
      }
    }
  }

  onMouseEnter = handleCursor('pointer');

  onMouseLeave = handleCursor('grab');

  onClickHandler = (e: any) => {
    const {
      location: { pathname },
      taskSolution,
    } = this.props;
    const { lat, lng } = e.lngLat;
    const { pipe } = e.features[0].properties;
    const pipeData = JSON.parse(pipe) as PipelineNetworkSegment &
      PipelineNetworkSegmentCalibrationResult;

    const segments = taskSolution?.segments as CalibrationSolution['segments'];

    if (segments) {
      const segment = segments.filter(
        el => el?.segment?.uid === pipeData.uid,
      )[0];

      if (segment) {
        pipeData.dp_abs = segment.dp_abs;
        pipeData.dp_rel = segment.dp_rel;
        pipeData.pressure_calc = segment.pressure_calc;
        pipeData.pressure_fact = segment.pressure_fact;
      }
    }
    const adapted = pathname.includes('adapted');
    const popupType = adapted ? 'adaptation' : 'pipe';

    this.handlePopup({
      popupCoords: [lng, lat],
      showPopup: true,
      popup: popupType,
      activeValue: {
        ...pipeData,
        coords: [lng, lat],
      },
    });
  };

  handlePopup = ({ activeValue, popup }: HandlePopupType) => {
    this.props.setPopupData({
      popupType: popup,
      ...activeValue,
    });
  };

  render() {
    const { taskSolution, wellfieldData, gradientType } = this.props;
    let pipelines = [] as PipelineNetworkSegment[];
    if (wellfieldData) {
      pipelines = wellfieldData.pipelines;
    }
    if (taskSolution) {
      pipelines =
        taskSolution?.pipelines || taskSolution?.wellfield_graph?.pipelines;
    }

    if (!pipelines?.length) return null;

    const { minAndMax, difference } = this.state;

    return (
      <>
        {pipelines?.map(pipe => {
          const style = { 'line-color': '#0559FD', 'line-width': 2 };
          if (['wor', 'dpdl'].includes(gradientType)) {
            const key = gradientType === 'dpdl' ? 'dp_dl_atm_km' : 'wor';
            const value = pipe[key];
            if (value && difference) {
              const ratio = (value - minAndMax.min) / difference;
              const color3 = pickHex3(
                ratio,
                firstColor,
                middleColor,
                secondColor,
              );
              style['line-color'] = `${color3}`;
            }
          }

          const properties = {
            pipe,
            type: 'pipe',
          };

          const { coords } = pipe;
          if (!coords) return null;

          return (
            <Layer
              id={`pipe ${pipe.uid}`}
              key={pipe.uid}
              type="line"
              layout={layout}
              paint={style}
              geoJSONSourceOptions={{ generateId: true }}
            >
              <Feature
                onMouseLeave={this.onMouseLeave}
                onMouseEnter={this.onMouseEnter}
                onClick={this.onClickHandler}
                coordinates={coords}
                properties={properties}
              />
            </Layer>
          );
        })}
      </>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
  wellfieldData: selectWellfieldData(store),
  gradientType: selectGradientType(store),
});

const mapDispatchToProps = { setPopupData, setGradientValues };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PipesLayer));
