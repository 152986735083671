import * as React from 'react';

import { formatter } from 'utils';
import { ITopRowLeftColProps } from './types';

const TopRowLeftCol: React.FC<ITopRowLeftColProps> = ({
  length,
  diameter,
  thickness,
}) => (
  <td className="popup-table-col">
    <div className="popup-row__sub-row">
      <p className="popup-val-dem">Длина, мм</p>
      <p className="popup-title">{formatter.format(length)}</p>
    </div>
    <div className="popup-row__sub-row">
      <p className="popup-val-dem">Внешний диаметр, мм</p>
      <p className="popup-title">{formatter.format(diameter)}</p>
    </div>
    <div className="popup-row__sub-row">
      <p className="popup-val-dem">Толщина стенки, мм</p>
      <p className="popup-title">{formatter.format(thickness)}</p>
    </div>
  </td>
);

export default TopRowLeftCol;
