import * as React from 'react';

import { ReactComponent as ZoomIn } from 'assets/images/zoomIn.svg';
import { ReactComponent as ZoomOut } from 'assets/images/zoomOut.svg';
import classnames from 'classnames';

import { ICustomZoomButtonProps } from './types';

const CustomZoomButton: React.FC<ICustomZoomButtonProps> = ({
  zoomHandler,
  zoomType,
}) => {
  const onClickHandler = () => zoomHandler(zoomType);

  return (
    <button
      onClick={onClickHandler}
      className={classnames('custom-zoom-control__button', zoomType)}
    >
      {zoomType === 'zoom-in' ? <ZoomIn /> : <ZoomOut />}
    </button>
  );
};

export default CustomZoomButton;
