import * as React from 'react';
import { withRouter } from 'react-router-dom';

import classnames from 'classnames';
import _throttle from 'lodash.throttle';

import BottomRow from './LeftColBottomRow';
import TopRowContainer from './TopRowContainer';
import { ILeftColState, LeftColProps } from './types';

import './style.scss';

class LeftCol extends React.Component<LeftColProps> {
  scrollContainer = React.createRef<HTMLDivElement>();

  state: ILeftColState = {
    showLink: false,
    scrolled: false,
  };

  componentDidMount() {
    this.handleRoute();
    this.scrollContainer.current?.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps: LeftColProps) {
    const { pathname } = this.props.location;
    const { hideLink } = this.props;
    if (prevProps.location.pathname !== pathname) {
      this.handleRoute();
    }
    if (prevProps.hideLink !== hideLink) {
      if (hideLink) {
        this.setState({ showLink: false });
      } else this.handleRoute();
    }
  }

  componentWillUnmount() {
    this.scrollContainer.current?.removeEventListener(
      'scroll',
      this.handleScroll,
    );
  }

  handleScroll = () => _throttle(this.updateScroll, 1000)();

  updateScroll = () => {
    const scrollTop = this.scrollContainer.current?.scrollTop;
    if (this.state.scrolled !== !!scrollTop) {
      this.setState({ scrolled: !!scrollTop });
    }
  };

  handleRoute = () => {
    const { pathname } = this.props.location;
    const isSettings = pathname.includes('settings');
    const isRestore = pathname.includes('restore');
    const showLink = isRestore ? false : !isSettings;

    this.setState({ showLink });
  };

  handleCoponents = () => {
    const { children } = this.props;
    const childrenComponents: React.ReactNode[] = [];
    const subTopRow: React.ReactNode[] = [];
    const bottomRow: React.ReactNode[] = [];
    React.Children.forEach(children, child => {
      if (!React.isValidElement(child)) return;
      const type = child.type as typeof child.type & { leftColRole: string };
      if (type.leftColRole === 'SubTopRow') subTopRow.push(child);
      else if (type.leftColRole === 'BottomRow') bottomRow.push(child);
      else childrenComponents.push(child);
    });

    return {
      childrenComponents,
      subTopRow,
      bottomRow,
    };
  };

  render() {
    const { showLink, scrolled } = this.state;
    const { subTopRow, childrenComponents, bottomRow } = this.handleCoponents();

    return (
      <div className={classnames('left-col', { scrolled })}>
        <TopRowContainer showLink={showLink}>{subTopRow}</TopRowContainer>
        <div ref={this.scrollContainer} className="left-col__main-container">
          {childrenComponents}
        </div>
        <BottomRow>{bottomRow}</BottomRow>
      </div>
    );
  }
}

export default withRouter(LeftCol);
