import React from 'react';

import { ParameterType } from '../types';
import TaskSettingsInput from './TaskSettingsInput';

import './taskSettings.scss';

interface ITaskSettingsProps {
  parameters: ParameterType[];
  setParameters(value: ParameterType[]);
}

const TaskSettings: React.FC<ITaskSettingsProps> = ({
  parameters,
  setParameters,
}) => {
  return (
    <div className="settings-content-container task-settings-container">
      <span className="settings-subtitle">3. Физико-химические свойства</span>
      <div className="settings-panel">
        <div className="task-settings-row">
          {parameters.map((item, index) => {
            if (index < 4) {
              return (
                <React.Fragment key={index}>
                  <TaskSettingsInput
                    index={index}
                    item={item}
                    parameters={parameters}
                    setParameters={setParameters}
                  />
                </React.Fragment>
              );
            }
            return <React.Fragment key={index} />;
          })}
        </div>
        <div className="clearfix" />
        <div className="task-settings-row">
          {parameters.map((item, index) => {
            if (index > 3) {
              return (
                <React.Fragment key={index}>
                  <TaskSettingsInput
                    index={index}
                    item={item}
                    parameters={parameters}
                    setParameters={setParameters}
                  />
                </React.Fragment>
              );
            }
            return <React.Fragment key={index} />;
          })}
          <div className="clearfix" />
        </div>
      </div>
    </div>
  );
};

export default TaskSettings;
