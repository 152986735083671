import { api } from 'api';

import { GetTraces, GetWellfieldGraph } from './types';

export const getTraces: GetTraces = id => {
  return api.get(
    'https://api.pipeline-network-optimization.numdes.com/api/traces',
    {
      params: {
        task_id: id,
      },
    },
  );
};

export const getWellfields = () => {
  return api.get(
    'https://api.pipeline-network-optimization.numdes.com/api/get_wellfields',
    {},
  );
};

export const getGatheringCenters = ({ wellfieldUid }) => {
  return api.get(
    'https://api.pipeline-network-optimization.numdes.com/api/get_gathering_centers',
    {
      params: {
        wellfield_uid: wellfieldUid,
      },
    },
  );
};

export const uploadFile = formData => {
  return api.post(
    'https://api.pipeline-network-optimization.numdes.com/api/upload_file',
    formData,
  );
};

export const getOverviewInfo = (
  techmodeFileUid: string,
  wellsTablesFileUid: string,
) => {
  return api.get(
    'https://api.pipeline-network-optimization.numdes.com/api/get_input_files_differences',
    {
      params: {
        techmode_file_uid: techmodeFileUid,
        wells_tables_file_uid: wellsTablesFileUid,
      },
    },
  );
};

export const getDateTimeOptions = (fileUid: string) => {
  return api.get(
    'https://api.pipeline-network-optimization.numdes.com/api/calibration_task/date',
    {
      params: {
        file_id: fileUid,
      },
    },
  );
};

export const restoreNetwork = ({
  uploadedFiles,
  centers,
  parameters,
  wellfieldUid,
  useAspo,
  useInclination,
}) => {
  const gcPressures = {};
  for (const center of centers) {
    const pressure = parseFloat(center.pressure?.replace(/,/, '.'));
    if (pressure) {
      gcPressures[center.uid] = pressure;
    } else {
      gcPressures[center.uid] = center.pressure;
    }
  }
  const filesArr: { uid: string; wellfield_uid: string; name: string }[] = [];
  for (const file of uploadedFiles) {
    filesArr.push({
      uid: file.uid,
      wellfield_uid: wellfieldUid,
      name: file.name,
    });
  }
  return api.post(
    'https://api.pipeline-network-optimization.numdes.com/api/calc_ident_task',
    {
      wellfield_uid: wellfieldUid,
      gc_pressures: gcPressures,
      files: filesArr,
      phys_chem: {
        roughness_mm: parseFloat(
          parameters[0].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        oil_density_kg_m3: parseFloat(
          parameters[1].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        water_density_kg_m3: parseFloat(
          parameters[2].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        gas_rel_density: parseFloat(
          parameters[3].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        oil_viscosity_20_cp: parseFloat(
          parameters[4].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        oil_viscosity_50_cp: parseFloat(
          parameters[5].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        fluid_temperature: parseFloat(
          parameters[6].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
      },
      init_node_states: [],
      use_aspo: useAspo,
      use_inclination: useInclination,
    },
  );
};

export const adaptNetwork = ({
  uploadedFiles,
  centers,
  parameters,
  adaptationMethod,
  wellfieldUid,
  date,
}) => {
  let inputFile = '';
  const files = [] as any;
  const gatheringCenters: string[] = centers.reduce(
    (acc: string[] = [], cur) => {
      acc.push(cur.uid);
      return acc;
    },
    [],
  );
  uploadedFiles.forEach(file => {
    if (file.name.split('.')[0] === 'Фактические измерения из OIS Pipe') {
      inputFile = file.uid;
    } else {
      files.push({
        uid: file.uid,
        wellfield_uid: wellfieldUid,
        name: file.name,
      });
    }
  });

  return api.post(
    'https://api.pipeline-network-optimization.numdes.com/api/calc_calibration_task',
    {
      wellfield_uid: wellfieldUid,
      input_file: inputFile,
      gathering_centers: gatheringCenters,
      date,
      files,
      phys_chem: {
        roughness_mm: parseFloat(
          parameters[0].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        oil_density_kg_m3: parseFloat(
          parameters[1].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        water_density_kg_m3: parseFloat(
          parameters[2].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        gas_rel_density: parseFloat(
          parameters[3].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        oil_viscosity_20_cp: parseFloat(
          parameters[4].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        oil_viscosity_50_cp: parseFloat(
          parameters[5].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
        fluid_temperature: parseFloat(
          parameters[6].value.replace(/\s/g, '').replace(/,/, '.'),
        ),
      },
      solver_platform: adaptationMethod,
    },
  );
};

export const createWellfield = (name: string, iskra_url: string) => {
  return api.post(
    'https://api.pipeline-network-optimization.numdes.com/api/wellfield',
    { name, iskra_url },
  );
};

export const getWellfieldGraph: GetWellfieldGraph = (wellfieldUid: string) => {
  return api.get(
    'https://api.pipeline-network-optimization.numdes.com/api/wellfield',
    { params: { wellfield_uid: wellfieldUid } },
  );
};
