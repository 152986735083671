import React, { createRef } from 'react';
import { ValueType } from 'react-select';

import { overflowOnResize } from '../overflowOnResize';
import ScrollableWrapper from '../ScrollableWrapper';
import { CenterType, OptionType } from '../types';
import GatheringCenterRow from './GatheringCenterRow';
import SelectGatheringCenterRow from './SelectGatheringCenterRow';

import './gatheringCenterSettings.scss';

interface IGatheringCenterSettingsProps {
  centerToAdd: CenterType;
  setCenterToAdd(value: CenterType);
  centerValue: ValueType<OptionType, false>;
  setCenterValue(value: ValueType<OptionType, false>): void;
  centers: CenterType[];
  setCenters(centers: CenterType[]): void;
  initOptions: OptionType[];
  centersOptions: ValueType<OptionType, false>[];
  setCentersOptions(options: ValueType<OptionType, false>[]);
}

interface IGatheringCenterSettingsState {
  panelStyle: React.CSSProperties;
  scrolled: boolean;
  added: boolean;
}

class GatheringCenterSettings extends React.Component<IGatheringCenterSettingsProps> {
  panelRef = createRef<HTMLDivElement>();

  state: IGatheringCenterSettingsState = {
    panelStyle: { overflow: 'unset' },
    scrolled: false,
    added: false,
  };

  componentDidMount(): void {
    const ref = this.panelRef.current;
    window.addEventListener('resize', this.onResize);
    ref?.addEventListener('scroll', this.onScroll);
    this.onResize();
  }

  componentDidUpdate(prevProps: IGatheringCenterSettingsProps): void {
    if (prevProps.centers.length !== this.props.centers.length) {
      this.onResize();
      this.onAdd();
    }
  }

  componentWillUnmount(): void {
    const ref = this.panelRef.current;
    window.removeEventListener('resize', this.onResize);
    ref?.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    const { scrolled } = this.state;
    this.setState({ scrolled: !scrolled });
  };

  onAdd = () => {
    const { added } = this.state;
    this.setState({ added: !added });
  };

  onResize = () => {
    overflowOnResize(this.panelRef, this.setPanelStyle);
  };

  setPanelStyle = style => {
    this.setState({ panelStyle: style });
  };

  onDeleteCenter = (index: number, center: string) => {
    if (index > -1) {
      const centersCopy = [...this.props.centers];
      centersCopy.splice(index, 1);
      this.props.setCenters(centersCopy);
      const currentOptions = [...this.props.centersOptions];
      const ix = this.props.initOptions.findIndex(obj => {
        return obj.label === center;
      });
      if (ix > -1) {
        currentOptions.splice(ix, 0, this.props.initOptions[ix]);
      }
      this.props.setCentersOptions(currentOptions);
    }
  };

  render() {
    const { panelStyle, scrolled, added } = this.state;
    const {
      centerToAdd,
      centers,
      setCenters,
      setCenterToAdd,
      centersOptions,
      setCentersOptions,
      centerValue,
      setCenterValue,
    } = this.props;
    return (
      <ScrollableWrapper
        divRef={this.panelRef}
        bottomShadowClass="settings-bottom-shadow"
      >
        <div
          className="settings-content-container gathering-center-settings-container"
          ref={this.panelRef}
          style={panelStyle}
        >
          <span className="settings-subtitle">2. Выбор центров сбора</span>
          <div className="settings-panel">
            <SelectGatheringCenterRow
              centerToAdd={centerToAdd}
              setCenterToAdd={setCenterToAdd}
              centers={centers}
              setCenters={setCenters}
              centersOptions={centersOptions}
              setCentersOptions={setCentersOptions}
              centerValue={centerValue}
              setCenterValue={setCenterValue}
            />
            <div className="clearfix" />
            {centers.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <GatheringCenterRow
                    centerIx={index}
                    item={item}
                    centers={centers}
                    setCenters={setCenters}
                    onDeleteCenter={this.onDeleteCenter}
                    panRef={this.panelRef}
                    scrolled={scrolled}
                    added={added}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </ScrollableWrapper>
    );
  }
}

export default GatheringCenterSettings;
